import { loginRequest } from '../auth/config.ts';
import { msalInstance } from '../auth/msal.tsx';
import { CONFIG } from '../utils';

export async function* streamRequest(url: string, body: object) {
  const authHeader: { authorization?: string } = {};
  const account = msalInstance.getActiveAccount();
  if (account) {
    const authResponse = await msalInstance.acquireTokenSilent({ ...loginRequest, account: account });
    authHeader['authorization'] = `Bearer ${authResponse.accessToken}`;
  }

  const response = await fetch(`${CONFIG.BASE_API_URL}${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Server responded with ${response.status}: ${errorData.message}`);
  }
  const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader();

  if (reader) {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  }
}
