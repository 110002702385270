import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  icon: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  reviewInput: {
    width: theme.spacing(35),
  },
}));
