import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  },
  activityArea: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'minmax(100px, auto)',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(3),
      gridTemplateColumns: '1fr',
    },
  },
}));
