import { ReactElement, StrictMode } from 'react';

import { Provider as StoreProvider } from 'react-redux';
import { coreStore } from '../store';
import { AppInsightsProvider } from './AppInsightsProvider.tsx';
import { AuthProvider } from './AuthProvider.tsx';
import { HeaderProvider } from './HeaderProvider.tsx';
import { SnackbarProvider } from './SnackbarProvider.tsx';
import { ThemeProvider } from './ThemeProvider.tsx';

export const CoreProvider = ({ children }: { children: ReactElement }) => (
  <StrictMode>
    <AppInsightsProvider>
      <AuthProvider>
        <StoreProvider store={coreStore}>
          <ThemeProvider>
            <HeaderProvider>
              <SnackbarProvider>{children}</SnackbarProvider>
            </HeaderProvider>
          </ThemeProvider>
        </StoreProvider>
      </AuthProvider>
    </AppInsightsProvider>
  </StrictMode>
);
