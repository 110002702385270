import { BrandingDto } from '../models/dto/configuration/branding.ts';
import { baseApi } from './baseApi.ts';

const configApi = baseApi.enhanceEndpoints({ addTagTypes: ['Branding'] }).injectEndpoints({
  endpoints: build => ({
    getBranding: build.query<BrandingDto, void>({
      query: () => '/configuration/sites/branding',
      providesTags: ['Branding'],
    }),
  }),
});

export const { useGetBrandingQuery: useBranding } = configApi;
