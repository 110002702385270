import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

type HeaderContextType = {
  menuItems: ReactNode[];
  setMenuItems: Dispatch<SetStateAction<ReactNode[]>>;
};

const HeaderContext = createContext<HeaderContextType>({ menuItems: [] as ReactNode[] } as HeaderContextType);

export const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const [menuItems, setMenuItems] = useState<ReactNode[]>([]);

  return <HeaderContext.Provider value={{ menuItems, setMenuItems }}>{children}</HeaderContext.Provider>;
};

export const useHeader = () => useContext(HeaderContext);
