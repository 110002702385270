import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';
import { ReactNode } from 'react';

import { useStyles } from './Drawer.styles.ts';

interface IProps {
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
  anchor?: DrawerProps['anchor'];
}

export const Drawer = ({ isOpen, anchor, close, children }: IProps) => {
  const { classes, cx } = useStyles();
  return (
    <>
      {/* Default drawer */}
      <MuiDrawer variant="permanent" anchor={anchor} className={cx(classes.root, classes.default)}>
        {children}
      </MuiDrawer>

      {/* Mobile drawer */}
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={close}
        ModalProps={{ keepMounted: true }}
        className={cx(classes.root, classes.mobile)}
      >
        {children}
      </MuiDrawer>
    </>
  );
};
