import { Stack } from '@sgde/core';
import { useEffect, useState } from 'react';
import { Conversation } from '../components/chat/Conversation.tsx';
import { Conversations } from '../components/chat/Conversations.tsx';
import { ConversationDto } from '../models/dto/chat/conversation.ts';
import { useConversations } from '../store/chatApi.ts';

export const Chat = () => {
  const [organisationId, setOrganisationId] = useState<number | undefined>();
  const { data: conversations } = useConversations({ organisationId });
  const [currentConversation, setCurrentConversation] = useState<ConversationDto>({ id: 0, name: 'Conversatie noua' });

  useEffect(() => {
    if (conversations?.length) {
      setCurrentConversation(conversations.at(0)!);
    }
  }, [conversations]);

  return (
    <Stack direction="row" height="100%">
      <Conversation conversation={currentConversation} organisationId={organisationId} />
      <Conversations
        conversation={currentConversation}
        onChangeConversation={setCurrentConversation}
        onChangeOrganisation={setOrganisationId}
      />
    </Stack>
  );
};
