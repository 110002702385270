import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactElement } from 'react';
import { CONFIG } from '../utils';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: CONFIG.APP_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

export const AppInsightsProvider = ({ children }: { children: ReactElement }) => (
  <AppInsightsErrorBoundary onError={() => <h1>Something went wrong please refresh</h1>} appInsights={reactPlugin}>
    <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
  </AppInsightsErrorBoundary>
);

export const useAppInsights = () => appInsights;
