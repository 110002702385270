import { ContentCopy as ContentCopyIcon, Done as DoneIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useStyles } from './CopyToClipboard.styles.ts';

interface IProps {
  text: string;
}
export const CopyToClipboard = ({ text }: IProps) => {
  const { classes } = useStyles();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000);
    }
  }, [isCopied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return isCopied ? (
    <Tooltip title="Copiat">
      <DoneIcon className={classes.root} fontSize="small" />
    </Tooltip>
  ) : (
    <Tooltip title="Copiaza">
      <ContentCopyIcon className={classes.root} fontSize="small" onClick={handleCopy} />
    </Tooltip>
  );
};
