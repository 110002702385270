import { RoleDto } from '../models';
import { baseApi } from './baseApi.ts';

const authorizationApi = baseApi.enhanceEndpoints({ addTagTypes: ['CurrentRoles', 'Roles'] }).injectEndpoints({
  endpoints: build => ({
    getCurrentRoles: build.query<RoleDto[], void>({
      query: () => '/authorization/roles/current',
      providesTags: ['CurrentRoles'],
    }),
    getRoles: build.query<RoleDto[], void>({
      query: () => '/authorization/roles',
      providesTags: ['Roles'],
    }),
  }),
});

export const { useGetCurrentRolesQuery: useCurrentRoles, useGetRolesQuery: useRoles } = authorizationApi;
