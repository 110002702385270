import { makeStyles } from '../theme.ts';

export const useStyles = makeStyles()(theme => ({
  root: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));
