import {
  Avatar,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
  debounce,
  useCurrentUser,
  useUserRoles,
} from '@sgde/core';
import { useCallback, useEffect, useState } from 'react';
import { AddressDto } from '../../models/dto/user/address.ts';
import { BillingInfoDto } from '../../models/dto/user/billingInfo.ts';
import { ProfileDto } from '../../models/dto/user/profile.ts';
import { useCurrentUserProfile, useUpdateCurrentUserProfile } from '../../store/userApi.ts';
import { Privacy } from './Privacy.tsx';
import { useStyles } from './ProfileForm.styles.ts';
import { Terms } from './Terms.tsx';

export const ProfileForm = () => {
  const { classes } = useStyles();
  const { data: user } = useCurrentUser();
  const { hasRole } = useUserRoles();
  const { data: defaultProfile } = useCurrentUserProfile();
  const [updateProfile] = useUpdateCurrentUserProfile();
  const [profile, setProfile] = useState<Partial<ProfileDto>>({});
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  const debounceUpdate = useCallback(
    debounce(updatedProfile => updateProfile(updatedProfile), 1000),
    []
  );

  const handleChange = (updatedProfile: Partial<ProfileDto>) => {
    setProfile(updatedProfile);
    debounceUpdate(updatedProfile);
  };

  useEffect(() => {
    if (defaultProfile) {
      setProfile(defaultProfile ?? {});
    }
  }, [defaultProfile]);

  return (
    <>
      <Box className={classes.container}>
        <Card elevation={3}>
          <CardContent className={classes.content}>
            <Avatar alt="User" className={classes.avatar} />
            <TextField label="Nume" variant="standard" defaultValue={user?.username} disabled />
            <TextField label="Email" variant="standard" defaultValue={user?.email} disabled />
            <TextField
              label="Specializare"
              variant="standard"
              value={profile.profession ?? ''}
              onChange={({ target: { value } }) => handleChange({ ...profile, profession: value })}
            />
            <Stack direction="row" gap={1} divider={<Divider orientation="vertical" flexItem />}>
              <Link component="button" variant="caption" onClick={() => setOpenTerms(true)}>
                Termeni și Condiții de Utilizare
              </Link>
              <Link component="button" variant="caption" onClick={() => setOpenPrivacy(true)}>
                Politica de Confidențialitate
              </Link>
            </Stack>
          </CardContent>
        </Card>
        <Card elevation={3}>
          <CardContent className={classes.content}>
            <Typography variant="h5">Domiciliu</Typography>
            <Divider sx={{ margin: '0 -24px' }} />
            <TextField
              label="Adresa"
              variant="standard"
              value={profile.address?.streetAddress ?? ''}
              onChange={({ target: { value } }) =>
                handleChange({
                  ...profile,
                  address: { ...profile?.address, streetAddress: value } as AddressDto,
                })
              }
            />
            <TextField
              label="Localitate"
              variant="standard"
              value={profile.address?.city ?? ''}
              onChange={({ target: { value } }) =>
                handleChange({
                  ...profile,
                  address: { ...profile?.address, city: value } as AddressDto,
                })
              }
            />
            <TextField
              label="Judet"
              variant="standard"
              value={profile.address?.county ?? ''}
              onChange={({ target: { value } }) =>
                handleChange({
                  ...profile,
                  address: { ...profile?.address, county: value } as AddressDto,
                })
              }
            />
          </CardContent>
        </Card>
        {hasRole('Contributor') && (
          <Card elevation={3}>
            <CardContent className={classes.content}>
              <Typography variant="h5">Date facturare</Typography>
              <Divider sx={{ margin: '0 -24px' }} />
              <TextField
                label="Denumire"
                variant="standard"
                value={profile?.billingInfo?.title ?? ''}
                onChange={({ target: { value } }) =>
                  handleChange({
                    ...profile,
                    billingInfo: { ...profile?.billingInfo, title: value } as BillingInfoDto,
                  })
                }
              />
              <TextField
                label="CIF/CUI/CNP"
                variant="standard"
                value={profile.billingInfo?.fiscalNumber ?? ''}
                onChange={({ target: { value } }) =>
                  handleChange({
                    ...profile,
                    billingInfo: { ...profile?.billingInfo, fiscalNumber: value } as BillingInfoDto,
                  })
                }
              />
              <TextField
                label="Adresa"
                variant="standard"
                value={profile.billingInfo?.address?.streetAddress ?? ''}
                onChange={({ target: { value } }) =>
                  handleChange({
                    ...profile,
                    billingInfo: {
                      ...profile?.billingInfo,
                      address: { ...profile.address, streetAddress: value } as AddressDto,
                    } as BillingInfoDto,
                  })
                }
              />
              <TextField
                label="Localitate"
                variant="standard"
                value={profile.billingInfo?.address?.city ?? ''}
                onChange={({ target: { value } }) =>
                  handleChange({
                    ...profile,
                    billingInfo: {
                      ...profile?.billingInfo,
                      address: { ...profile.address, city: value } as AddressDto,
                    } as BillingInfoDto,
                  })
                }
              />
              <TextField
                label="Judet"
                variant="standard"
                value={profile.billingInfo?.address?.county ?? ''}
                onChange={({ target: { value } }) =>
                  handleChange({
                    ...profile,
                    billingInfo: {
                      ...profile?.billingInfo,
                      address: { ...profile.address, county: value } as AddressDto,
                    } as BillingInfoDto,
                  })
                }
              />
            </CardContent>
          </Card>
        )}
      </Box>
      <Dialog
        open={openTerms || openPrivacy}
        onClose={() => {
          setOpenTerms(false);
          setOpenPrivacy(false);
        }}
      >
        <DialogContent>{openTerms ? <Terms /> : <Privacy />}</DialogContent>
      </Dialog>
    </>
  );
};
