export const Terms = () => {
  return <TermsContent />;
};

const TermsContent = () => (
  <div>
    <div>
      <h1>Termeni și Condiții de Utilizare</h1>
      <br />
      <p>
        Bun venit la platforma AgroChatGPT și AgroChat, dezvoltată și operată de SGDE Application. Prin accesarea și
        utilizarea acestor soluții, sunteți de acord cu termenii și condițiile prezentate în acest document. Vă rugăm să
        citiți acești termeni cu atenție înainte de a utiliza serviciile noastre.
      </p>
    </div>

    <div>
      <h2>Definiții</h2>
      <ul>
        <li>
          <strong>Utilizator:</strong> Orice persoană fizică sau juridică care accesează și utilizează soluțiile
          AgroChatGPT și AgroChat în scop personal.
        </li>
        <li>
          <strong>Moderator:</strong> Orice persoană desemnată de SGDE Application pentru a verifica și a valida
          răspunsurile oferite de prompt în cadrul soluțiilor AgroChatGPT și AgroChat.
        </li>
        <li>
          <strong>Contributor:</strong> Orice persoană care furnizează informații, documente sau articole care
          contribuie la instruirea și îmbunătățirea AgroChatGPT și AgroChat, respectând drepturile de autor asupra
          acestor materiale.
        </li>
        <li>
          <strong>Noi, nostru, compania:</strong> SGDE Application și orice entitate asociată care furnizează aceste
          soluții.
        </li>
        <li>
          <strong>Servicii:</strong> Platforma AgroChatGPT și AgroChat, inclusiv orice caracteristici, funcționalități
          și conținut asociat.
        </li>
      </ul>
    </div>

    <div>
      <h2>1 Înregistrarea și Tipurile de Utilizatori</h2>
      <h3>1.1 Înregistrarea Contului</h3>
      <p>
        Pentru a utiliza serviciile AgroChatGPT și AgroChat, utilizatorii trebuie să se înregistreze și să creeze un
        cont. În timpul procesului de înregistrare, utilizatorii vor furniza informații exacte, complete și actualizate.
        SGDE Application își rezervă dreptul de a refuza înregistrarea sau de a anula contul unui utilizator în cazul în
        care informațiile furnizate sunt false, incomplete sau înșelătoare.
      </p>
      <h3>1.2 Tipuri de Utilizatori</h3>
      <ul>
        <li>
          <strong>Utilizator:</strong> Utilizatorii sunt persoanele care utilizează serviciile în scopuri personale sau
          profesionale, fără a contribui la dezvoltarea sau moderarea conținutului. Utilizatorii au acces la
          funcționalitățile de bază ale serviciilor și pot primi răspunsuri generate de AgroChatGPT & AgroChat.
        </li>
        <li>
          <strong>Moderator:</strong> Moderatorii sunt responsabili pentru verificarea și validarea răspunsurilor
          generate de AgroChatGPT & AgroChat. Aceștia asigură calitatea și acuratețea conținutului, corectând
          eventualele erori și raportând orice comportament inadecvat sau conținut neconform. Moderatorii au acces la
          instrumente suplimentare pentru gestionarea și moderarea conținutului.
        </li>
        <li>
          <strong>Contributor:</strong> Contributorii contribuie la instruirea și îmbunătățirea AgroChatGPT & AgroChat
          prin furnizarea de documente, articole și cărți asupra cărora dețin drepturi de autor. Aceștia ajută la
          extinderea bazei de cunoștințe a serviciilor și la îmbunătățirea performanței generale. Contributorii trebuie
          să garanteze că materialele furnizate sunt conforme cu drepturile de autor și că au permisiunea necesară
          pentru utilizarea acestora.
        </li>
      </ul>
      <h3>1.3 Confidențialitatea Datelor de Înregistrare</h3>
      <p>
        SGDE Application se angajează să protejeze confidențialitatea datelor de înregistrare ale utilizatorilor în
        conformitate cu politica noastră de confidențialitate. Utilizatorii sunt responsabili pentru menținerea
        confidențialității informațiilor de autentificare și pentru toate activitățile care au loc în contul lor.
      </p>
      <h3>1.4 Securitatea Contului</h3>
      <p>
        Utilizatorii trebuie să informeze imediat SGDE Application despre orice utilizare neautorizată a contului lor
        sau despre orice altă breșă de securitate. SGDE Application nu va fi responsabilă pentru pierderile sau daunele
        rezultate din neglijența utilizatorului în protejarea informațiilor de autentificare.
      </p>
      <h3>1.5 Încetarea Accesului</h3>
      <p>
        SGDE Application își rezervă dreptul de a suspenda sau înceta accesul utilizatorilor la servicii în orice
        moment, fără notificare prealabilă, în cazul în care utilizatorii încalcă acești termeni și condiții sau
        desfășoară activități care pot aduce prejudicii SGDE Application, altor utilizatori sau terților.
      </p>
    </div>

    <div>
      <h2>2 Drepturi de Proprietate Intelectuală</h2>
      <h3>2.1 Proprietatea asupra Conținutului și Serviciilor</h3>
      <p>
        Toate drepturile de proprietate intelectuală asupra serviciilor noastre, inclusiv dar fără a se limita la codul
        sursă, designul, textele, grafica, logo-urile, imaginile, interfețele utilizator și orice alt conținut asociat
        serviciilor AgroChatGPT și AgroChat, sunt deținute exclusiv de SGDE Application și sunt protejate de legile
        naționale și internaționale privind drepturile de autor, mărcile comerciale, brevetele și alte drepturi de
        proprietate intelectuală.
      </p>
      <h3>2.2 Utilizarea Restricționată a Conținutului</h3>
      <p>Utilizatorii nu au permisiunea să:</p>
      <ul>
        <li>
          Copieze, modifice, distribuie, afișeze public, efectueze public, transmită sau să folosească în alt mod orice
          parte a serviciilor sau conținutului fără consimțământul nostru scris prealabil.
        </li>
        <li>
          Utilizeze orice robot, spider, aplicație de căutare sau recuperare de site-uri sau orice alt dispozitiv
          automat sau proces manual pentru a recupera, indexa, "mina date" sau a reproduce sau a ocoli structura de
          navigare sau prezentarea serviciilor noastre fără permisiunea noastră expresă în scris.
        </li>
        <li>
          Elimine notificările de drepturi de autor, mărci comerciale sau alte notificări de proprietate conținute în
          cadrul serviciilor noastre.
        </li>
        <li>
          Reproducă, copieze, vândă, revândă sau exploateze orice parte a serviciilor în scopuri comerciale fără
          permisiunea noastră scrisă prealabilă.
        </li>
      </ul>
      <h3>2.3 Conținut Generat de Utilizatori</h3>
      <p>
        Utilizatorii care contribuie cu conținut la instruirea AgroChatGPT și AgroChat (denumiți "Contributori")
        garantează că dețin toate drepturile necesare asupra materialelor furnizate și că acestea nu încalcă drepturile
        de proprietate intelectuală ale terților. Prin furnizarea de conținut, Contributorii acordă SGDE Application o
        licență neexclusivă, gratuită, perpetuă, irevocabilă și sublicențiabilă de a utiliza, reproduce, modifica,
        adapta, publica, traduce, crea lucrări derivate din, distribui și afișa acest conținut în întreaga lume prin
        orice mijloace media.
      </p>
      <h3>2.4 Raportarea Încălcărilor</h3>
      <p>
        Dacă considerați că orice conținut sau material din cadrul serviciilor noastre încalcă drepturile dvs. de
        proprietate intelectuală, vă rugăm să ne contactați imediat la adresa de e-mail office@sgde.ro, furnizând
        detalii suficiente pentru ca noi să putem investiga și, dacă este cazul, să luăm măsurile necesare.
      </p>
      <h3>2.5 Sancțiuni pentru Încălcări</h3>
      <p>
        Ne rezervăm dreptul de a suspenda sau a închide conturile utilizatorilor care încalcă repetat sau grav
        drepturile de proprietate intelectuală, fără notificare prealabilă. De asemenea, ne rezervăm dreptul de a lua
        măsuri legale împotriva oricăror persoane sau entități care încalcă aceste drepturi.
      </p>
      <h3>2.6 Disclaimer</h3>
      <p>
        Deși depunem toate eforturile pentru a proteja drepturile de proprietate intelectuală și pentru a ne asigura că
        conținutul furnizat în cadrul serviciilor noastre este legal și autorizat, nu putem garanta că toate materialele
        sunt complet lipsite de încălcări ale drepturilor de autor sau ale altor drepturi de proprietate intelectuală.
        Utilizatorii sunt încurajați să raporteze orice astfel de încălcări pentru a ne permite să luăm măsurile
        adecvate.
      </p>
    </div>

    <div>
      <h2>3 Confidențialitatea</h2>
      <h3>3.1 Colectarea și Utilizarea Datelor</h3>
      <p>
        Respectăm confidențialitatea datelor dvs. personale și ne angajăm să protejăm informațiile pe care ni le
        furnizați. Colectăm, stocăm și utilizăm informațiile dvs. personale în conformitate cu politica noastră de
        confidențialitate, care este parte integrantă a acestor termeni și condiții. Informațiile colectate includ, dar
        nu se limitează la, datele de contact, informațiile de autentificare, datele de utilizare și preferințele
        utilizatorilor.
      </p>
      <h3>3.2 Scopul Colectării Datelor</h3>
      <p>
        Datele personale colectate sunt utilizate pentru a oferi, întreține și îmbunătăți serviciile noastre, pentru a
        personaliza experiența dvs., pentru a comunica cu dvs. și pentru a vă oferi suport tehnic. De asemenea, putem
        folosi aceste date pentru a vă trimite informații de marketing sau notificări legate de serviciile noastre, cu
        consimțământul dvs. prealabil.
      </p>
      <h3>3.3 Protecția Datelor</h3>
      <p>
        Implementăm măsuri de securitate tehnice și organizatorice adecvate pentru a proteja datele dvs. personale
        împotriva accesului neautorizat, pierderii, distrugerii sau modificării. Aceste măsuri includ, dar nu se
        limitează la, criptarea datelor, controlul accesului și stocarea sigură a informațiilor. Cu toate acestea,
        trebuie să înțelegeți că nicio metodă de transmitere a datelor prin internet sau metodă de stocare electronică
        nu este complet sigură și nu putem garanta securitatea absolută a datelor dvs.
      </p>
      <h3>3.4 Divulgarea Informațiilor</h3>
      <p>
        Nu vom vinde, închiria sau transfera informațiile dvs. personale către terți fără consimțământul dvs., cu
        excepția cazurilor în care acest lucru este necesar pentru a furniza serviciile noastre, pentru a respecta
        obligațiile legale sau pentru a proteja drepturile și securitatea noastră sau ale altor utilizatori. Putem
        dezvălui informații personale unor terți furnizori de servicii care ne ajută în desfășurarea activităților
        noastre, cu condiția ca aceștia să respecte confidențialitatea și să utilizeze datele doar în scopurile
        specificate de noi.
      </p>
      <h3>3.5 Accesul și Controlul asupra Datelor</h3>
      <p>
        Aveți dreptul de a accesa, actualiza, corecta sau șterge informațiile dvs. personale pe care le deținem. De
        asemenea, aveți dreptul de a restricționa sau de a vă opune prelucrării datelor dvs. personale și de a solicita
        portabilitatea acestora. Pentru a exercita aceste drepturi, vă rugăm să ne contactați la adresa{' '}
        <a href="mailto:office@sgde.ro">office@sgde.ro</a>. Vom răspunde solicitărilor dvs. în termenul prevăzut de
        legislația aplicabilă.
      </p>
      <h3>3.6 Cookie-uri și Tehnologii Similare</h3>
      <p>
        Utilizăm cookie-uri și tehnologii similare pentru a colecta date de utilizare, pentru a îmbunătăți
        funcționalitatea serviciilor noastre și pentru a vă oferi o experiență personalizată. Puteți gestiona
        preferințele privind cookie-urile prin setările browserului dvs., dar rețineți că dezactivarea cookie-urilor
        poate afecta funcționarea serviciilor noastre.
      </p>
      <h3>3.7 Modificări ale Politicii de Confidențialitate</h3>
      <p>
        Ne rezervăm dreptul de a modifica această politică de confidențialitate în orice moment. Orice modificări vor fi
        postate pe site-ul nostru și vor intra în vigoare imediat. Continuarea utilizării serviciilor noastre după
        publicarea modificărilor reprezintă acceptarea acestora. Vă încurajăm să verificați periodic această secțiune
        pentru a fi la curent cu cele mai recente actualizări.
      </p>
      <h3>3.8 Contact</h3>
      <p>
        Pentru orice întrebări, preocupări sau solicitări legate de confidențialitatea datelor dvs., vă rugăm să ne
        contactați la adresa de e-mail <a href="mailto:office@sgde.ro">office@sgde.ro</a>.
      </p>
    </div>

    <div>
      <h2>4 Responsabilitatea Utilizatorului</h2>
      <h3>4.1 Respectarea Legii și a Termenilor</h3>
      <p>
        Utilizatorii sunt responsabili pentru respectarea tuturor legilor și reglementărilor aplicabile în timpul
        utilizării serviciilor noastre. Aceasta include, dar nu se limitează la, legile privind protecția datelor,
        drepturile de autor, confidențialitatea și comunicarea electronică. Utilizatorii trebuie să utilizeze serviciile
        noastre într-o manieră legală și etică, fără a încălca drepturile altor utilizatori sau ale terților.
      </p>
      <h3>4.2 Acuratețea Informațiilor</h3>
      <p>
        Utilizatorii sunt responsabili pentru asigurarea acurateței și actualizării informațiilor pe care le furnizează.
        Aceasta include informațiile de înregistrare, datele de contact și orice alt conținut încărcat sau transmis prin
        intermediul serviciilor noastre. SGDE Application nu își asumă responsabilitatea pentru eventualele consecințe
        rezultate din furnizarea de informații incorecte sau incomplete.
      </p>
      <h3>4.3 Securitatea Contului</h3>
      <p>
        Utilizatorii sunt responsabili pentru menținerea confidențialității informațiilor de autentificare, inclusiv a
        numelui de utilizator și a parolei. Utilizatorii nu trebuie să partajeze aceste informații cu terți și trebuie
        să ne informeze imediat despre orice utilizare neautorizată a contului sau orice altă încălcare a securității.
        SGDE Application nu este responsabilă pentru pierderile sau daunele rezultate din neglijența utilizatorului în
        protejarea informațiilor de autentificare.
      </p>
      <h3>4.4 Activități Interzise</h3>
      <p>Utilizatorilor le este interzis să:</p>
      <ul>
        <li>Utilizeze serviciile noastre pentru a desfășura activități ilegale, frauduloase sau neautorizate.</li>
        <li>
          Transmită sau distribuie viruși, malware, spyware sau orice alt software dăunător prin intermediul serviciilor
          noastre.
        </li>
        <li>Interfereze cu funcționarea serviciilor noastre sau cu utilizarea acestora de către alți utilizatori.</li>
        <li>
          Colecteze sau să încerce să colecteze informații personale despre alți utilizatori fără consimțământul
          acestora.
        </li>
        <li>Încalce drepturile de proprietate intelectuală ale SGDE Application sau ale altor terți.</li>
      </ul>
      <h3>4.5 Conținut Generat de Utilizatori</h3>
      <p>
        Utilizatorii sunt responsabili pentru orice conținut pe care îl generează, încarcă sau distribuie prin
        intermediul serviciilor noastre, inclusiv, dar fără a se limita la, mesaje, comentarii, imagini și fișiere.
        Utilizatorii garantează că dețin toate drepturile necesare pentru a distribui acest conținut și că acesta nu
        încalcă drepturile terților. SGDE Application își rezervă dreptul de a elimina orice conținut considerat
        neadecvat, ilegal sau care încalcă acești termeni și condiții.
      </p>
      <h3>4.6 Răspunderea pentru Daune</h3>
      <p>
        Utilizatorii sunt de acord să despăgubească și să exonereze SGDE Application, angajații, afiliații, partenerii
        și reprezentanții săi de orice revendicări, pierderi, daune, răspunderi și cheltuieli (inclusiv onorariile
        avocaților) rezultate din utilizarea necorespunzătoare a serviciilor noastre sau din încălcarea acestor termeni
        și condiții de către utilizatori.
      </p>
      <h3>4.7 Suspendarea și Încetarea Contului</h3>
      <p>
        SGDE Application își rezervă dreptul de a suspenda sau de a înceta contul oricărui utilizator care încalcă
        acești termeni și condiții sau care desfășoară activități ce pot aduce prejudicii serviciilor noastre, altor
        utilizatori sau terților. În cazul unei astfel de suspendări sau încetări, utilizatorul nu va avea dreptul la
        nicio despăgubire sau rambursare.
      </p>
    </div>

    <div>
      <h2>7 Modificări ale Termenilor și Condițiilor</h2>
      <h3>5.1 Dreptul de Modificare</h3>
      <p>
        SGDE Application își rezervă dreptul de a modifica, actualiza sau schimba acești termeni și condiții în orice
        moment. Orice modificări vor fi postate pe site-ul nostru și vor intra în vigoare imediat după publicare. Este
        responsabilitatea utilizatorilor să verifice periodic acești termeni și condiții pentru a fi la curent cu cele
        mai recente actualizări.
      </p>
      <h3>5.2 Notificarea Modificărilor</h3>
      <p>
        În cazul în care facem modificări semnificative la acești termeni și condiții, vom încerca să informăm
        utilizatorii prin intermediul serviciilor noastre, prin e-mail sau prin alte mijloace de comunicare rezonabile.
        Aceste notificări vor include informații despre modificările efectuate și data la care acestea vor intra în
        vigoare.
      </p>
      <h3>5.3 Acceptarea Modificărilor</h3>
      <p>
        Continuarea utilizării serviciilor noastre după publicarea modificărilor constituie acceptarea acestor
        modificări de către utilizatori. Dacă nu sunteți de acord cu termenii și condițiile modificate, vă rugăm să
        încetați utilizarea serviciilor noastre și să vă închideți contul, dacă este cazul.
      </p>
      <h3>5.4 Recomandări pentru Utilizatori</h3>
      <p>
        Recomandăm utilizatorilor să verifice periodic această secțiune pentru a fi informați despre orice modificări.
        Utilizatorii pot accesa versiunea actualizată a termenilor și condițiilor oricând pe site-ul nostru. În cazul în
        care aveți întrebări sau nelămuriri cu privire la modificările efectuate, vă rugăm să ne contactați la adresa de
        e-mail <a href="mailto:office@sgde.ro">office@sgde.ro</a>.
      </p>
      <h3>5.5 Modificări ale Serviciilor</h3>
      <p>
        Ne rezervăm dreptul de a modifica, suspenda sau înceta orice parte a serviciilor noastre în orice moment, fără
        notificare prealabilă. Aceasta poate include adăugarea sau eliminarea de caracteristici, funcționalități sau
        conținut. SGDE Application nu va fi responsabilă pentru nicio pierdere sau daună suferită de utilizatori ca
        urmare a acestor modificări.
      </p>
    </div>

    <div>
      <h2>6 Limitarea Răspunderii</h2>
      <h3>6.1 Răspundere Limitată</h3>
      <p>
        În măsura maximă permisă de lege, SGDE Application, afiliații săi, partenerii, angajații, agenții și
        reprezentanții săi nu vor fi responsabili pentru niciun fel de daune indirecte, incidentale, speciale, punitive
        sau de consecință, inclusiv, dar fără a se limita la, pierderi de profituri, date, utilizare, goodwill sau alte
        pierderi intangibile, rezultate din (i) utilizarea sau incapacitatea de a utiliza serviciile noastre; (ii)
        accesul neautorizat la sau alterarea transmisiilor sau datelor dvs.; (iii) declarații sau comportamente ale
        oricărei terțe părți în cadrul serviciilor; sau (iv) orice altă problemă legată de serviciile noastre.
      </p>
      <h3>6.2 Răspunderea Maximă</h3>
      <p>
        Răspunderea cumulată totală a SGDE Application față de utilizatori pentru orice pretenții care decurg din sau
        sunt legate de utilizarea sau incapacitatea de a utiliza serviciile noastre nu va depăși suma totală plătită de
        utilizator către SGDE Application pentru utilizarea serviciilor, dacă este cazul, în perioada de trei luni
        anterioară evenimentului care a generat pretenția.
      </p>
      <h3>6.3 Excluderi și Limitări</h3>
      <p>
        Unele jurisdicții nu permit excluderea anumitor garanții sau limitarea sau excluderea răspunderii pentru daune
        accidentale sau de consecință. În consecință, unele dintre limitările de mai sus pot să nu vi se aplice. În
        aceste cazuri, răspunderea SGDE Application va fi limitată în măsura permisă de lege.
      </p>
      <h3>6.4 Fără Garanții</h3>
      <p>
        Serviciile noastre sunt furnizate "ca atare" și "așa cum sunt disponibile", fără nicio garanție de niciun fel,
        fie expresă, implicită sau statutară. SGDE Application nu garantează că serviciile vor fi neîntrerupte, fără
        erori sau complet sigure. SGDE Application își declină în mod expres orice garanție de vandabilitate, potrivire
        pentru un anumit scop, titlu și neîncălcare a drepturilor de proprietate intelectuală.
      </p>
      <h3>6.5 Răspunderea pentru Conținut</h3>
      <p>
        Utilizatorii sunt responsabili pentru conținutul pe care îl generează și îl distribuie prin intermediul
        serviciilor noastre. SGDE Application nu este responsabilă pentru nicio pierdere sau daună rezultată din
        utilizarea conținutului generat de utilizatori sau din încrederea în acesta.
      </p>
      <h3>6.6 Forță Majoră</h3>
      <p>
        SGDE Application nu va fi responsabilă pentru nicio întârziere sau neîndeplinire a obligațiilor sale în
        conformitate cu acești termeni și condiții, dacă întârzierea sau neîndeplinirea rezultă din orice cauză care
        este în afara controlului rezonabil al SGDE Application, inclusiv, dar fără a se limita la, acte de forță
        majoră, războaie, dezastre naturale, întreruperi ale furnizării de energie electrică sau internet, atacuri
        cibernetice, sau orice alte evenimente de natură similară.
      </p>
    </div>

    <div>
      <h2>7 Garanții și Declarații</h2>
      <h3>7.1 Declarații Generale</h3>
      <p>
        SGDE Application depune toate eforturile pentru a asigura că serviciile noastre sunt de înaltă calitate și
        funcționale. Cu toate acestea, serviciile sunt furnizate „ca atare” și „așa cum sunt disponibile”. Utilizatorii
        înțeleg și acceptă că folosesc serviciile pe propriul risc.
      </p>
      <h3>7.2 Excluderea Garanțiilor</h3>
      <p>
        În măsura maximă permisă de lege, SGDE Application nu oferă nicio garanție expresă, implicită sau statutară cu
        privire la serviciile noastre. Aceasta include, dar nu se limitează la, garanții implicite de vandabilitate,
        potrivire pentru un anumit scop, titlu și neîncălcare a drepturilor. SGDE Application nu garantează că
        serviciile vor îndeplini cerințele dvs. sau că vor fi disponibile în mod neîntrerupt, sigur sau fără erori.
      </p>
      <h3>7.3 Absența Garanțiilor privind Informațiile</h3>
      <p>
        SGDE Application nu oferă garanții cu privire la acuratețea, completitudinea sau utilitatea informațiilor
        furnizate prin intermediul serviciilor noastre. Informațiile furnizate sunt destinate exclusiv scopurilor de
        informare generală și nu constituie sfaturi profesionale.
      </p>
      <h3>7.4 Limitarea Responsabilității pentru Conținutul Terților</h3>
      <p>
        Serviciile noastre pot conține linkuri către site-uri sau servicii ale terților care nu sunt deținute sau
        controlate de SGDE Application. Nu ne asumăm nicio responsabilitate pentru conținutul, politicile de
        confidențialitate sau practicile site-urilor sau serviciilor terților. Utilizatorii accesează aceste site-uri pe
        propriul risc.
      </p>
    </div>

    <div>
      <h2>8 Diverse</h2>
      <h3>8.1 Acord Integral</h3>
      <p>
        Acești termeni și condiții, împreună cu politica de confidențialitate și orice alte politici sau reguli de
        utilizare postate pe site-ul nostru, constituie întregul acord între dvs. și SGDE Application cu privire la
        utilizarea serviciilor noastre și înlocuiesc orice acorduri anterioare, scrise sau verbale, referitoare la
        același subiect.
      </p>
      <h3>8.2 Renunțare și Divizibilitate</h3>
      <p>
        Niciun eșec sau întârziere din partea SGDE Application în exercitarea oricărui drept, putere sau privilegiu
        prevăzut în acești termeni și condiții nu va constitui o renunțare la acestea. Dacă orice prevedere a acestor
        termeni și condiții este considerată invalidă sau inaplicabilă de către o instanță competentă, celelalte
        prevederi vor rămâne în vigoare și efectele acesteia nu vor fi afectate.
      </p>
      <h3>8.3 Legislația Aplicabilă</h3>
      <p>
        Acești termeni și condiții sunt guvernați și interpretați în conformitate cu legile din [jurisdicția dvs.].
        Orice litigii rezultate din sau în legătură cu acești termeni și condiții vor fi soluționate exclusiv de
        instanțele competente din [jurisdicția dvs.].
      </p>
      <h3>8.4 Contact</h3>
      <p>
        Pentru orice întrebări sau nelămuriri legate de acești termeni și condiții, vă rugăm să ne contactați la adresa
        de e-mail <a href="mailto:office@sgde.ro">office@sgde.ro</a>.
      </p>
      <h3>8.5 Transferul Drepturilor</h3>
      <p>
        SGDE Application poate transfera, cesiona sau delega oricare dintre drepturile și obligațiile sale în
        conformitate cu acești termeni și condiții, fără consimțământul dvs. prealabil. Utilizatorii nu pot transfera,
        cesiona sau delega drepturile și obligațiile lor fără consimțământul scris prealabil al SGDE Application.
      </p>
      <h3>8.6 Forță Majoră</h3>
      <p>
        SGDE Application nu va fi responsabilă pentru nicio întârziere sau neîndeplinire a obligațiilor sale care
        rezultă din evenimente de forță majoră, inclusiv, dar fără a se limita la, acte de Dumnezeu, dezastre naturale,
        războaie, acte de terorism, revolte, embargouri, acte ale autorităților civile sau militare, incendii,
        inundații, accidente, greve sau lipsa mijloacelor de transport, combustibil, energie, forță de muncă sau
        materiale.
      </p>
    </div>
  </div>
);
