import {
  AuthenticationGateway,
  AuthorizationGuard,
  Error,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from '@sgde/core';
import { Layout } from '../components/Layout.tsx';
import { Chat } from '../pages/Chat.tsx';
import { Home } from '../pages/Home.tsx';
import { Organisation } from '../pages/Organisation.tsx';
import { Organisations } from '../pages/Organisations.tsx';
import { Profile } from '../pages/Profile.tsx';
import { Resources } from '../pages/Resources.tsx';
import { Users } from '../pages/Users.tsx';
import { ROUTES } from './routes.ts';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<Layout />}
      errorElement={
        <Layout>
          <Error />
        </Layout>
      }
    >
      <Route path={ROUTES.HOME.path} element={<Home />} />
      <Route element={<AuthenticationGateway />}>
        <Route path={ROUTES.CHAT.path} element={<Chat />} />
        <Route path={ROUTES.PROFILE.path} element={<Profile />} />
        <Route element={<AuthorizationGuard requiredRole="Contributor" fallbackPath={ROUTES.CHAT.path} />}>
          <Route path={ROUTES.RESOURCES.path} element={<Resources />} />
        </Route>
        <Route element={<AuthorizationGuard requiredRole="Admin" fallbackPath={ROUTES.PROFILE.path} />}>
          <Route path={ROUTES.USERS.path} element={<Users />} />
        </Route>
        <Route path={ROUTES.ORGANISATION.path} element={<Organisation />} />
        <Route element={<AuthorizationGuard requiredRole="Admin" fallbackPath={ROUTES.PROFILE.path} />}>
          <Route path={ROUTES.ORGANISATIONS.path} element={<Organisations />} />
        </Route>
      </Route>
    </Route>
  )
);
