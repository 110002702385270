import { BlocksContent, BlocksRenderer, Card, CardContent, Divider, Typography } from '@sgde/core';
import { useStyles } from './ActivityCard.styles';

type ActivityCardParams = {
  extend?: boolean;
  imageSrc?: string;
  subtitle?: string;
  title: string;
  description?: BlocksContent;
};

export const ActivityCard = ({ extend, imageSrc, subtitle, title, description }: ActivityCardParams) => {
  const { classes } = useStyles({ imageSrc, extend });
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {imageSrc && <Typography variant="overline">{subtitle}</Typography>}
        <Typography variant="h4" fontWeight="bold">
          {title}
        </Typography>
        {!imageSrc && (
          <>
            <Divider className={classes.divider} color="white" />
            {description && (
              <Typography variant="h5">
                <BlocksRenderer content={description} />
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
