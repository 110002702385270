import { makeStyles } from '../theme.ts';

export const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: 'unset',
    backgroundImage: 'unset',
  },
  toolbar: {
    backgroundColor: theme.palette.background.default,
    gap: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      gap: theme.spacing(1 / 2),
    },
  },
}));
