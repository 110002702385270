import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(8),
    padding: theme.spacing(8),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5),
      gap: theme.spacing(5),

      '& img': {
        height: theme.spacing(5),
      },

      '& h5': {
        fontSize: '1rem',
      },
    },

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      gap: theme.spacing(3),

      '& img': {
        height: theme.spacing(3),
      },

      '& h5': {
        fontSize: '1rem',
      },
    },
  },
}));
