import { LightMode, NightsStay, SettingsBrightness } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent } from 'react';
import { ThemeMode } from '../models/theme.ts';
import { useThemeMode } from '../theme.ts';

export const ThemeSwitcher = () => {
  const { themeMode, setThemeMode } = useThemeMode();
  const handleChange = (_: MouseEvent, newMode: ThemeMode) => setThemeMode(newMode);

  return (
    <ToggleButtonGroup size="small" exclusive value={themeMode} onChange={handleChange}>
      <ToggleButton value="light">
        <LightMode fontSize="small" />
      </ToggleButton>
      <ToggleButton value="system">
        <SettingsBrightness fontSize="small" />
      </ToggleButton>
      <ToggleButton value="dark">
        <NightsStay fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
