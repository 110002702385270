import { makeStyles } from '../theme.ts';

export const useStyles = makeStyles()(theme => ({
  root: {
    width: theme.spacing(30),
    '& .MuiDrawer-paper': { width: 'inherit' },
  },
  default: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },

    '& > .MuiDrawer-paper': {
      top: 'unset',
    },
  },
  mobile: {
    [theme.breakpoints.not('xs')]: {
      display: 'none',
    },
  },
}));
