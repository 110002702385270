import { Avatar, CopyToClipboard, Stack, Typography, useBranding } from '@sgde/core';
import { FeedbackDto } from '../../models/dto/chat/feedback.ts';
import { useStyles } from './Message.styles.ts';
import { MessageFeedback } from './MessageFeedback.tsx';

type MessageProps = {
  variant: 'question' | 'response';
  message: string;
  feedback?: FeedbackDto;
};

export const Message = ({ variant, message, feedback }: MessageProps) => {
  const { classes } = useStyles();

  const { data: branding } = useBranding();

  return (
    <Stack direction="row" className={classes.message}>
      <Avatar className={classes.avatar} src={variant === 'question' ? undefined : branding?.logo} />
      <Stack gap={1}>
        <Typography component="div" dangerouslySetInnerHTML={{ __html: message }} />
        {variant === 'response' && (
          <Stack direction="row" gap={1}>
            <CopyToClipboard text={message} />
            <MessageFeedback {...feedback} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
