import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    cursor: 'pointer',
    '& .MuiAvatar-root': {
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
  },
}));
