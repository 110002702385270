import {
  Box,
  DataGrid,
  DeleteIcon,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridSlots,
  Stack,
  UserDto,
} from '@sgde/core';
import { useState } from 'react';
import { OrganisationDto } from '../../models/dto/organisation/organisation.ts';
import { useOrganisations, useRemoveOrganisationMember } from '../../store/organisationApi.ts';
import { useStyles } from './OrganisationTable.styles.ts';
import { Toolbar } from './OrganisationTableToolbar.tsx';

export const OrganisationTable = () => {
  const { classes } = useStyles();
  const { data: organisations } = useOrganisations();
  const [organisation, setOrganisation] = useState(organisations?.[0] ?? undefined);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const { columns } = useColumns(organisation);

  return (
    <DataGrid
      className={classes.root}
      columns={columns}
      rows={organisation?.users ?? []}
      hideFooterSelectedRowCount
      slots={{
        toolbar: Toolbar as GridSlots['toolbar'],
        noRowsOverlay: NoRowsOverlay,
        noResultsOverlay: NoResultsOverlay,
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      slotProps={{ toolbar: { setOrganisation, setPaginationModel } }}
      columnVisibilityModel={{ identifierType: false }}
    />
  );
};

const useActions = (organisation?: OrganisationDto) => {
  const [removeUser] = useRemoveOrganisationMember();

  const getDefaultActions = (memberEmail: string) => [
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label="Delete"
      onClick={() => removeUser({ id: organisation?.id ?? 0, memberEmail })}
      color="inherit"
    />,
  ];

  return { getDefaultActions };
};

const useColumns = (organisation?: OrganisationDto) => {
  const { getDefaultActions } = useActions(organisation);

  const columns = [
    {
      field: 'username',
      headerName: 'Nume',
      disableColumnMenu: true,
      flex: 3,
    },
    {
      field: 'email',
      headerName: 'Email',
      disableColumnMenu: true,
      flex: 3,
    },
    {
      field: 'actions',
      type: 'actions',
      resizable: false,
      getActions: ({ row: { email } }: GridRowParams<UserDto>) => getDefaultActions(email),
    },
  ] as GridColDef[];

  return { columns };
};

const NoRowsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu există utilizatori in aceasta organizatie. Pentru a adăuga noi utilizatori, apăsați butonul '+'.</Box>
  </Stack>
);

const NoResultsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu au fost găsiți utilizatori.</Box>
  </Stack>
);
