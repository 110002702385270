import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { ISnackbarProps, Snackbar } from '../components/Snackbar.tsx';

type ISnackbarOpenProps = Omit<ISnackbarProps, 'open' | 'onClose'>;

interface ISnackbarContextProps {
  openSnackbar: (props: ISnackbarOpenProps) => void;
}
interface ISnackbarProviderProps {
  children: ReactNode;
}

const SnackbarContext = createContext({} as ISnackbarContextProps);
export const useSnackbar = () => useContext(SnackbarContext);
export const SnackbarProvider = ({ children }: ISnackbarProviderProps) => {
  const [open, setOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<ISnackbarOpenProps>({ message: '' });

  const openSnackbar = useCallback((props: ISnackbarOpenProps) => {
    setOpen(true);
    setSnackbarProps(props);
  }, []);

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <Snackbar open={open} onClose={() => setOpen(false)} {...snackbarProps} />
    </SnackbarContext.Provider>
  );
};
