import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../providers';
import { useUserRoles } from '../utils';

interface IAuthorizationGuardProps {
  requiredRole: string;
  fallbackPath: string;
}
export const AuthorizationGuard = ({ requiredRole, fallbackPath }: IAuthorizationGuardProps) => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { hasRole, isFetching } = useUserRoles();

  useEffect(() => {
    if (!isFetching && !hasRole(requiredRole)) {
      openSnackbar({ severity: 'error', message: 'Nu aveti permisiunea necesara pentru a accesa aceasta pagina.' });
      navigate(fallbackPath);
    }
  }, [hasRole, isFetching, navigate, openSnackbar, requiredRole]);

  return <Outlet />;
};
