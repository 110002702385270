import { Alert, Stack } from '@mui/material';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const Error = () => {
  const error = useRouteError();
  const errorMessage = useErrorMessage(error);

  return (
    <Stack component="main" width="100%" height="100%" justifyContent="center" alignItems="center">
      <Alert severity="warning" elevation={3}>
        {errorMessage}
      </Alert>
    </Stack>
  );
};

const useErrorMessage = (error: unknown): string => {
  if (isRouteErrorResponse(error)) {
    return `${error.status} ${error.statusText}`;
  } else if (error instanceof window.Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return 'Unknown error';
  }
};
