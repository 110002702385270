import { BaseDto, baseApi, streamRequest, useAppDispatch } from '@sgde/core';
import { useEffect, useState } from 'react';
import { ConversationDto } from '../models/dto/chat/conversation.ts';
import { FeedbackDto } from '../models/dto/chat/feedback.ts';
import { MessageDto } from '../models/dto/chat/message.ts';
import { UserChatInfo } from '../models/dto/chat/userChatInfo.ts';

const chatApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Conversations', 'Messages', 'UserChatInfo'] })
  .injectEndpoints({
    endpoints: build => ({
      getConversations: build.query<ConversationDto[], { organisationId?: number }>({
        query: ({ organisationId }) => `/chat/conversations?organisationId=${organisationId || ''}`,
        providesTags: ['Conversations'],
      }),
      createConversation: build.mutation<
        ConversationDto,
        { conversation: Omit<ConversationDto, 'id'>; organisationId?: number }
      >({
        query: ({ conversation, organisationId }) => ({
          url: `/chat/conversations?organisationId=${organisationId || ''}`,
          method: 'POST',
          body: conversation,
        }),
        async onQueryStarted({ organisationId }, { dispatch, queryFulfilled }) {
          const { data: newConversation } = await queryFulfilled;
          dispatch(
            chatApi.util.updateQueryData('getConversations', { organisationId }, conversations => {
              conversations[0].id = newConversation.id;
              conversations[0].name = newConversation.name;
            })
          );
          dispatch(chatApi.util.upsertQueryData('getConversationMessages', { id: newConversation.id ?? 0 }, []));
        },
      }),
      deleteConversation: build.mutation<void, BaseDto>({
        query: ({ id }) => ({ url: `/chat/conversations/${id}`, method: 'DELETE' }),
        invalidatesTags: ['Conversations'],
      }),
      getConversationMessages: build.query<MessageDto[], BaseDto>({
        query: ({ id }) => `/chat/conversations/${id}/messages`,
        providesTags: ['Messages'],
      }),
      getUserChatInfo: build.query<UserChatInfo, void>({
        query: () => '/chat/conversations/chatInfo',
        providesTags: ['UserChatInfo'],
      }),
      provideFeedback: build.mutation<void, Partial<FeedbackDto>>({
        query: body => ({
          url: `/chat/conversations/${body.conversationId}/messages/${body.messageId}/feedback`,
          method: 'POST',
          body,
        }),
        async onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            chatApi.util.updateQueryData('getConversationMessages', { id: patch.conversationId }, draft => {
              const message = draft.find(m => m.id === patch.messageId);
              if (message) {
                Object.assign(message, { ...message, feedback: patch });
              }
            })
          );
          try {
            await queryFulfilled;
            if (!patch.id) {
              dispatch(chatApi.util.invalidateTags(['Messages']));
            }
          } catch {
            patchResult.undo();
          }
        },
      }),
    }),
  });

export const {
  useGetConversationsQuery: useConversations,
  useCreateConversationMutation: useCreateConversation,
  useDeleteConversationMutation: useDeleteConversation,
  useGetConversationMessagesQuery: useConversationMessages,
  useProvideFeedbackMutation: useProvideFeedback,
  useGetUserChatInfoQuery: useUserChatInfo,
} = chatApi;

export const useCreateConversationLocally = () => {
  const dispatch = useAppDispatch();
  return [
    ({ organisationId }: { organisationId?: number }) =>
      dispatch(
        chatApi.util.updateQueryData('getConversations', { organisationId }, conversations => {
          const localConversation = conversations.find(c => c.id === 0);
          if (!localConversation) {
            conversations.unshift({ id: 0, name: 'Conversatie noua' });
          }
        })
      ),
  ];
};

export const useMessageResponse = (): [(data: MessageDto) => void, { data: string; isCompleted: boolean }] => {
  const [data, setData] = useState<MessageDto>();
  const [isCompleted, setCompleted] = useState(true);
  const [response, setResponse] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    const reply = async () => {
      setResponse('');
      setCompleted(false);
      const streamResponse = streamRequest(
        data?.organisationId !== undefined && data?.organisationId >= 0
          ? `/chat/conversations/${data?.organisationId}/${data?.id}/messages/stream`
          : `/chat/conversations/${data?.id}/messages/stream`,
        {
          text: data?.text,
        }
      );
      for await (const line of streamResponse) {
        await new Promise(resolve => {
          const appendChar = (text: string) => {
            if (text.length) {
              setResponse(msg => msg + text[0]);
              setTimeout(() => appendChar(text.slice(1)), 1);
            } else {
              resolve(undefined);
            }
          };

          appendChar(line);
        });
      }
      setCompleted(true);
      dispatch(chatApi.util.invalidateTags(['Messages']));
    };
    if (0 < (data?.id ?? 0) && data?.text) {
      dispatch(
        chatApi.util.updateQueryData('getConversationMessages', { id: data?.id ?? 0 }, messages => {
          messages.push({ id: -1 - messages.length, text: data.text, type: 'User' });
          messages.push({ id: 0, text: '', type: 'Bot' });
        })
      );
      dispatch(
        chatApi.util.updateQueryData('getUserChatInfo', undefined, userChatInfo => {
          userChatInfo.promptCounter += 1;
        })
      );
      reply();
    }
  }, [data]);

  useEffect(() => {
    if (response) {
      dispatch(
        chatApi.util.updateQueryData('getConversationMessages', { id: data?.id ?? 0 }, messages => {
          messages[messages.length - 1].text = response;

          if (isCompleted) {
            messages[messages.length - 1].id = -1 - messages.length;
          }
        })
      );
    }
  }, [response]);

  return [(data: MessageDto) => setData(data), { data: response, isCompleted }];
};
