import {
  CheckIcon,
  Popover,
  ReviewIcon,
  Stack,
  StarIcon,
  StarOutlinedIcon,
  TextField,
  ThumbDownIcon,
  ThumbDownOutlinedIcon,
  ThumbUpIcon,
  ThumbUpOutlinedIcon,
  Tooltip,
  useUserRoles,
} from '@sgde/core';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { FeedbackDto } from '../../models/dto/chat/feedback.ts';
import { useProvideFeedback } from '../../store/chatApi.ts';
import { useStyles } from './MessageFeedback.styles.ts';

type Props = Partial<FeedbackDto>;

export const MessageFeedback = (feedback: Props) => {
  const { hasRole } = useUserRoles();

  return hasRole('Moderator') ? (
    <>
      <Rating {...feedback} />
      <Review {...feedback} />
    </>
  ) : (
    <Likes {...feedback} />
  );
};

const Likes = ({ liked, ...feedback }: Props) => {
  const { classes } = useStyles();
  const [providedFeedback] = useProvideFeedback();
  const handleLike = (liked?: boolean) => providedFeedback({ ...feedback, liked });

  return (
    <>
      <Tooltip title="Satisfactor">
        {liked ? (
          <ThumbUpIcon fontSize="small" className={classes.icon} onClick={() => handleLike(undefined)} />
        ) : (
          <ThumbUpOutlinedIcon fontSize="small" className={classes.icon} onClick={() => handleLike(true)} />
        )}
      </Tooltip>
      <Tooltip title="Nesatisfacator">
        {liked === false ? (
          <ThumbDownIcon fontSize="small" className={classes.icon} onClick={() => handleLike(undefined)} />
        ) : (
          <ThumbDownOutlinedIcon fontSize="small" className={classes.icon} onClick={() => handleLike(false)} />
        )}
      </Tooltip>
    </>
  );
};

const Rating = ({ rating, ...feedback }: Props) => {
  const { classes } = useStyles();
  const [providedFeedback] = useProvideFeedback();
  const handleRating = (rating?: number) => providedFeedback({ ...feedback, rating });

  return (
    <Tooltip title="Scor">
      <Stack direction="row">
        {[...Array(5)].map((_, i) =>
          rating && i < rating ? (
            <StarIcon
              key={`${feedback?.id ?? 0}-${i}`}
              fontSize="small"
              className={classes.icon}
              onClick={() => handleRating(i + 1 === rating ? undefined : i + 1)}
            />
          ) : (
            <StarOutlinedIcon
              key={`${feedback?.id ?? 0}-${i}`}
              fontSize="small"
              className={classes.icon}
              onClick={() => handleRating(i + 1)}
            />
          )
        )}
      </Stack>
    </Tooltip>
  );
};

const Review = ({ review, ...feedback }: Props) => {
  const { classes } = useStyles();
  const [newReview, setReview] = useState(review);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [providedFeedback] = useProvideFeedback();
  const handleReview = () => {
    providedFeedback({ ...feedback, review: newReview });
    handleClose();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setReview(event.currentTarget.value);
  const handleClick = (event: MouseEvent) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Tooltip title="Recenzie">
        <ReviewIcon fontSize="small" className={classes.icon} onClick={handleClick} />
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <TextField
          multiline
          maxRows={5}
          label="Recenzie"
          variant="filled"
          defaultValue={review}
          onChange={handleChange}
          className={classes.reviewInput}
          InputProps={{ endAdornment: <CheckIcon onClick={handleReview} className={classes.icon} /> }}
        />
      </Popover>
    </>
  );
};
