import overpassLatine from '@fontsource-variable/overpass/files/overpass-latin-ext-wght-normal.woff2';

import {
  LinkProps,
  PaletteOptions,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
  useMediaQuery,
} from '@mui/material';
import { createContext, useContext, useMemo } from 'react';
import { makeStyles as makeMuiStyles } from 'tss-react/mui';
import { RouterLink } from './components/RouterLink.tsx';
import { ThemeMode } from './models';
import { useBranding } from './store';

export const useTheme = ({ mode }: { mode: ThemeMode }) => {
  const { data: branding, isLoading } = useBranding();
  const isSystemDark = useMediaQuery('(prefers-color-scheme: dark)');
  const isDarkMode = useMemo(
    () => mode === ThemeMode.Dark || (mode === ThemeMode.System && isSystemDark),
    [isSystemDark, mode]
  );
  const palette = useMemo(
    () =>
      ({
        mode: isDarkMode ? 'dark' : 'light',
        ...((branding?.palette as PaletteOptions) ?? {}),
        background: isDarkMode
          ? { default: branding?.palette?.background?.dark, paper: branding?.palette?.background?.dark }
          : { default: branding?.palette?.background?.light, paper: branding?.palette?.background?.light },
      }) as PaletteOptions,
    [branding?.palette, isDarkMode]
  );

  return { theme: isLoading ? undefined : responsiveFontSizes(createTheme({ ...baseTheme, palette })) };
};

export const makeStyles = makeMuiStyles;

export type ThemeModeContextProps = {
  themeMode: ThemeMode;
  setThemeMode: (mode: ThemeMode) => void;
};

export const ThemeModeContext = createContext({} as ThemeModeContextProps);
export const useThemeMode = () => useContext(ThemeModeContext);

const baseTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Overpass Variable',
          src: `url(${overpassLatine})`,
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: '100 900',
          descentOverride: '25%',
          unicodeRange:
            'U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF',
        },
        '*': {
          boxSizing: 'border-box',
        },
        'html, body, #root': {
          height: '100%',
          overflow: 'hidden',
        },

        '::-webkit-scrollbar': {
          width: 15,
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          backgroundClip: 'content-box',
          border: '7px solid transparent',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundClip: 'content-box',
          borderRadius: 12,
          backgroundColor: '#9e9e9e',
          border: '6px solid transparent',
        },
        '::-webkit-scrollbar-thumb:hover': {
          border: '4px solid transparent',
        },
        '::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: RouterLink,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: RouterLink,
      },
    },
  },
  typography: {
    fontFamily: ['Overpass Variable', 'sans-serif'].join(','),
    allVariants: { letterSpacing: -1 },
    button: { textTransform: 'none' },
    caption: { fontSize: '0.76rem', lineHeight: 1 },
  },
  shape: { borderRadius: 25 },
};
