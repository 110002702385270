import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme,
} from '@sgde/core';
import { useState } from 'react';
import { useAddOrganisationMember } from '../../store/organisationApi';

interface IProps {
  organisationId: number;
  isOpen: boolean;
  handleClose: () => void;
}
export const OrganisationAddMemberDialog = ({ isOpen, handleClose, organisationId }: IProps) => {
  const [addMember] = useAddOrganisationMember();
  const [email, setEmail] = useState('');

  const theme = useTheme();
  const mode = theme.palette.mode;

  const handleAddMember = () => {
    addMember({ id: organisationId, memberEmail: email });
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <DialogTitle>Adauga membru</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pentru a adăuga un membru în organizație, introduceți adresa de email a persoanei pe care doriți să o
          adăugați.
        </DialogContentText>
        <TextField
          required
          fullWidth
          autoFocus
          name="email"
          type="email"
          margin="dense"
          variant="outlined"
          label="Email membru"
          onChange={e => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={mode === 'dark' ? 'secondary' : 'primary'} variant="outlined">
          Anuleaza
        </Button>
        <Button onClick={handleAddMember} color={mode === 'dark' ? 'secondary' : 'primary'} variant="outlined">
          Adauga
        </Button>
      </DialogActions>
    </Dialog>
  );
};
