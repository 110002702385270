import { useCallback } from 'react';
import { useCurrentRoles } from '../store';

export const useUserRoles = () => {
  const { data: roles, ...props } = useCurrentRoles();

  return {
    ...props,
    roles: roles || [],
    hasRole: useCallback(
      (roleName: string) =>
        roles?.some(role => role.name === roleName || role.name === 'Admin' || role.name === 'SuperAdmin'),
      [roles]
    ),
  };
};
