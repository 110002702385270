export const Privacy = () => {
  return <PrivacyContent />;
};

const PrivacyContent = () => (
  <div>
    <div>
      <h1>Politica de Confidențialitate</h1>
      <br />
      <p>
        Această Politică de Confidențialitate descrie modul în care SGDE Application ("noi", "ne" sau "compania")
        colectează, utilizează, stochează și protejează informațiile cu caracter personal ale utilizatorilor ("dvs.") în
        legătură cu utilizarea serviciilor noastre AgroChatGPT și AgroChat.
      </p>
    </div>

    <div>
      <h2>1 Informațiile Colectate</h2>
      <h3>1.1 Informații Furnizate de Utilizatori</h3>
      <ul>
        <li>
          <strong>Date de înregistrare:</strong> Nume, prenume, adresă de e-mail, număr de telefon, nume de utilizator
          și parolă.
        </li>
        <li>
          <strong>Profil utilizator:</strong> Fotografie, informații biografice, interese și preferințe.
        </li>
        <li>
          <strong>Comunicări:</strong> Mesaje trimise către serviciul nostru de asistență sau alte forme de comunicare.
        </li>
      </ul>
      <h3>1.2 Informații Colectate Automat</h3>
      <ul>
        <li>
          <strong>Informații de utilizare:</strong> Adresa IP, tipul de browser, paginile vizitate, timpul petrecut pe
          site, URL-ul de referință, informații despre dispozitiv și date de diagnosticare.
        </li>
        <li>
          <strong>Cookies și tehnologii similare:</strong> Utilizăm cookies și alte tehnologii de urmărire pentru a
          colecta informații despre activitatea dvs. pe site-ul nostru.
        </li>
      </ul>
      <h3>1.3 Informații de la Terți</h3>
      <ul>
        <li>
          <strong>Rețele sociale:</strong> Dacă alegeți să vă autentificați prin intermediul unei rețele sociale (de
          exemplu, Facebook, Google), vom colecta informații din profilul dvs. de pe rețeaua respectivă, în conformitate
          cu setările dvs. de confidențialitate.
        </li>
      </ul>
    </div>

    <div>
      <h2>2 Utilizarea Informațiilor</h2>
      <h3>2.1 Furnizarea și Îmbunătățirea Serviciilor</h3>
      <ul>
        <li>
          <strong>Personalizare:</strong> Utilizăm informațiile pentru a personaliza experiența dvs. și pentru a furniza
          conținut și caracteristici care sunt relevante pentru dvs.
        </li>
        <li>
          <strong>Îmbunătățirea serviciilor:</strong> Analizăm datele pentru a înțelege cum sunt utilizate serviciile
          noastre și pentru a le îmbunătăți.
        </li>
      </ul>
      <h3>2.2 Comunicare cu Utilizatorii</h3>
      <ul>
        <li>
          <strong>Notificări:</strong> Vă trimitem notificări despre actualizări, noutăți și alte informații legate de
          contul dvs. și utilizarea serviciilor noastre.
        </li>
        <li>
          <strong>Răspuns la solicitări:</strong> Utilizăm informațiile pentru a răspunde la întrebările și solicitările
          dvs.
        </li>
      </ul>
      <h3>2.3 Securitate și Prevenirea Fraudelor</h3>
      <ul>
        <li>
          <strong>Protecție:</strong> Utilizăm datele pentru a monitoriza și a proteja serviciile noastre împotriva
          fraudelor și altor activități neautorizate.
        </li>
        <li>
          <strong>Verificare:</strong> Verificăm identitatea utilizatorilor pentru a preveni accesul neautorizat.
        </li>
      </ul>
    </div>

    <div>
      <h2>3 Partajarea Informațiilor</h2>
      <h3>3.1 Partajare cu Terți</h3>
      <ul>
        <li>
          <strong>Furnizori de servicii:</strong> Partajăm informațiile cu furnizorii noștri de servicii care ne ajută
          să furnizăm și să îmbunătățim serviciile noastre.
        </li>
        <li>
          <strong>Parteneri de afaceri:</strong> Putem partaja informațiile cu partenerii noștri de afaceri în scopuri
          de marketing sau alte scopuri comerciale.
        </li>
      </ul>
      <h3>3.2 Transferuri Legale</h3>
      <ul>
        <li>
          <strong>Obligații legale:</strong> Putem divulga informațiile dvs. pentru a respecta cerințele legale, pentru
          a răspunde la solicitări legale sau pentru a proteja drepturile noastre.
        </li>
      </ul>
      <h3>3.3 Transferuri de Afaceri</h3>
      <ul>
        <li>
          <strong>Fuziuni și achiziții:</strong> În cazul unei fuziuni, achiziții sau vânzări de active, informațiile
          dvs. pot fi transferate ca parte a tranzacției.
        </li>
      </ul>
    </div>

    <div>
      <h2>4 Securitatea Informațiilor</h2>
      <h3>4.1 Măsuri de Securitate</h3>
      <ul>
        <li>
          <strong>Protecție fizică și tehnică:</strong> Implementăm măsuri tehnice și organizatorice pentru a proteja
          informațiile dvs. împotriva accesului neautorizat, pierderii, distrugerii sau alterării.
        </li>
      </ul>
      <h3>4.2 Limitarea Accesului</h3>
      <ul>
        <li>
          <strong>Acces limitat:</strong> Accesul la informațiile dvs. este restricționat doar la angajații, agenții și
          subcontractanții noștri care au nevoie de acestea pentru a furniza serviciile noastre.
        </li>
      </ul>
    </div>

    <div>
      <h2>5 Drepturile Utilizatorilor</h2>
      <h3>5.1 Acces și Corectare</h3>
      <ul>
        <li>
          <strong>Acces:</strong> Aveți dreptul să solicitați o copie a informațiilor dvs. cu caracter personal pe care
          le deținem.
        </li>
        <li>
          <strong>Corectare:</strong> Aveți dreptul să solicitați corectarea informațiilor dvs. dacă acestea sunt
          inexacte sau incomplete.
        </li>
      </ul>
      <h3>5.2 Ștergerea și Restricționarea Procesării</h3>
      <ul>
        <li>
          <strong>Ștergerea:</strong> Aveți dreptul să solicitați ștergerea informațiilor dvs. în anumite circumstanțe.
        </li>
        <li>
          <strong>Restricționare:</strong> Aveți dreptul să solicitați restricționarea procesării informațiilor dvs. în
          anumite situații.
        </li>
      </ul>
      <h3>5.3 Obiecții și Portabilitate</h3>
      <ul>
        <li>
          <strong>Obiecții:</strong> Aveți dreptul să obiectați la procesarea informațiilor dvs. în anumite condiții.
        </li>
        <li>
          <strong>Portabilitate:</strong> Aveți dreptul să primiți informațiile dvs. într-un format structurat și să le
          transmiteți unui alt operator de date.
        </li>
      </ul>
    </div>

    <div>
      <h2>6 Retenția Datelor</h2>
      <h3>6.1 Perioada de Retenție</h3>
      <ul>
        <li>
          <strong>Durata:</strong> Păstrăm informațiile dvs. doar atât timp cât este necesar pentru scopurile pentru
          care au fost colectate.
        </li>
        <li>
          <strong>Ștergere:</strong> După expirarea perioadei de retenție, vom șterge sau anonimiza informațiile dvs. în
          mod sigur.
        </li>
      </ul>
    </div>

    <div>
      <h2>7 Transferul Internațional de Date</h2>
      <h3>7.1 Transferuri în Afara SEE</h3>
      <ul>
        <li>
          <strong>Măsuri de protecție:</strong> Ne asigurăm că transferurile de date în afara Spațiului Economic
          European (SEE) sunt protejate prin măsuri adecvate, cum ar fi clauzele contractuale standard.
        </li>
      </ul>
    </div>

    <div>
      <h2>8 Modificări ale Politicii de Confidențialitate</h2>
      <h3>8.1 Actualizări</h3>
      <ul>
        <li>
          <strong>Notificări:</strong> Vom publica orice modificări aduse acestei Politici de Confidențialitate pe
          această pagină și vă vom notifica prin e-mail sau prin intermediul serviciilor noastre dacă modificările sunt
          semnificative.
        </li>
      </ul>
      <h3>8.2 Revizuiri Periodice</h3>
      <ul>
        <li>
          <strong>Revizuire regulată:</strong> Vă încurajăm să revizuiți periodic această Politică de Confidențialitate
          pentru a rămâne informat despre modul în care protejăm informațiile dvs.
        </li>
      </ul>
    </div>

    <div>
      <h2>9 Contact</h2>
      <h3>9.1 Date de Contact</h3>
      <ul>
        <li>
          <strong>Întrebări și solicitări:</strong> Dacă aveți întrebări, nelămuriri sau solicitări referitoare la
          această Politică de Confidențialitate sau la practicile noastre de confidențialitate, ne puteți contacta la:
          <ul>
            <li>E-mail: office@sgde.ro</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);
