import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  messagesWrapper: {
    // Hack to keep scroll on bottom
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  messages: {
    gap: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      gap: theme.spacing(1),
    },
  },
}));
