import { gridClasses, makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(() => ({
  root: {
    borderWidth: 0,

    [`& .${gridClasses.columnHeaderTitleContainer}`]: {
      justifyContent: 'space-between',
    },
    [`& .${gridClasses.columnSeparator}:not(.${gridClasses['columnSeparator--resizable']})`]: {
      display: 'none',
    },
  },
}));
