import { SupportMessageDto } from '@sgde/core';
import { baseApi } from './baseApi.ts';

const supportApi = baseApi.enhanceEndpoints({ addTagTypes: ['SupportMessage'] }).injectEndpoints({
  endpoints: build => ({
    sendMessage: build.mutation<void, Omit<SupportMessageDto, 'id'>>({
      query: body => ({
        url: '/support/send',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSendMessageMutation: useSendMessage } = supportApi;
