import { CardList as ActivityAreasProps, Box, Divider, Stack, Typography } from '@sgde/core';
import { Fragment } from 'react';
import { useStyles } from './ActivityAreas.styles';
import { ActivityCard } from './ActivityCard';

export const ActivityAreas = ({ cardLists }: { cardLists: ActivityAreasProps[] }) => {
  const { classes } = useStyles();
  return (
    <Stack gap={5}>
      {cardLists?.map(({ title, items }, index) => (
        <Fragment key={index}>
          <Divider variant="middle">
            {title && (
              <Typography variant="h4" className={classes.title}>
                {title}
              </Typography>
            )}
          </Divider>
          <Box className={classes.activityArea}>
            {items?.map(({ id, mainText, secondaryText, description, image }, index) => (
              <ActivityCard
                key={id}
                title={mainText}
                subtitle={secondaryText}
                description={description}
                imageSrc={image?.url}
                extend={index === 1 && items.length % 2 !== 0}
              />
            ))}
          </Box>
        </Fragment>
      ))}
    </Stack>
  );
};
