import {
  AddIcon,
  GridPaginationModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
  IconButton,
} from '@sgde/core';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useCreateOrganisationLocally } from '../../store/organisationApi.ts';

interface IProps {
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  setPaginationModel: Dispatch<SetStateAction<GridPaginationModel>>;
}

export const Toolbar = ({ setRowModesModel, setPaginationModel }: IProps) => {
  const [createOrganisation, { data: organisation }] = useCreateOrganisationLocally();

  const addOrganisation = () => {
    createOrganisation();
    setPaginationModel(pagination => ({ ...pagination, page: 0 }));
  };

  useEffect(() => {
    if (organisation) {
      setRowModesModel(model => ({
        ...model,
        [organisation.id!]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    }
  }, [organisation, setRowModesModel]);

  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
      <IconButton onClick={addOrganisation}>
        <AddIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};
