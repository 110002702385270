import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  Login as LoginIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { MouseEvent, ReactElement, useState } from 'react';
import { loginRequest } from '../auth/config.ts';
import { ThemeSwitcher } from './ThemeSwitcher.tsx';

interface IProps {
  children?: ReactElement;
  hideLogin?: boolean;
}

export const ProfileMenu = ({ children, hideLogin }: IProps) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogin = () => instance.loginRedirect(loginRequest);
  const handleLogout = async () => {
    handleClose();
    await instance.logout();
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>{open ? <MenuOpenIcon /> : <MenuIcon />}</IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ThemeSwitcher />
        </MenuItem>
        <Divider />
        {children}
        {!hideLogin && isAuthenticated && (
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        )}
        {!hideLogin && !isAuthenticated && (
          <MenuItem onClick={handleLogin}>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            Login
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
