import {
  AddIcon,
  Button,
  ChevronDownIcon,
  GridToolbarContainer,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useCurrentUser,
  useUserRoles,
} from '@sgde/core';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { OrganisationDto } from '../../models/dto/organisation/organisation';
import { useOrganisations } from '../../store/organisationApi';
import { OrganisationAddMemberDialog } from './OrganisationAddMemberDialog';

interface IProps {
  setOrganisation: Dispatch<SetStateAction<OrganisationDto>>;
}

export const Toolbar = ({ setOrganisation }: IProps) => {
  const { hasRole } = useUserRoles();
  const { data: currentUser } = useCurrentUser();
  const { data: organisations } = useOrganisations();
  const [organisationIndex, setOrganisationIndex] = useState(0);
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);
  const canAddMembers = useMemo(
    () => hasRole('Admin') || organisations?.[organisationIndex]?.owner.id === currentUser?.id,
    [currentUser?.id, hasRole, organisationIndex, organisations]
  );

  useEffect(() => {
    if (organisations?.length) {
      setOrganisation(organisations?.[organisationIndex]);
    }
  }, [setOrganisation, organisations, organisationIndex]);

  return (
    <>
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <OrganisationSwitcher organisationIndex={organisationIndex} setOrganisationIndex={setOrganisationIndex} />
        {canAddMembers && (
          <IconButton onClick={() => setIsAddMemberDialogOpen(true)}>
            <AddIcon />
          </IconButton>
        )}
      </GridToolbarContainer>
      <OrganisationAddMemberDialog
        isOpen={isAddMemberDialogOpen}
        organisationId={organisations?.[organisationIndex]?.id ?? 0}
        handleClose={() => setIsAddMemberDialogOpen(false)}
      />
    </>
  );
};

type OrganisationSwitcherProps = {
  organisationIndex: number;
  setOrganisationIndex: Dispatch<SetStateAction<number>>;
};
const OrganisationSwitcher = ({ organisationIndex, setOrganisationIndex }: OrganisationSwitcherProps) => {
  const { data: organisations } = useOrganisations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      {organisations && organisations?.length <= 1 ? (
        <Typography variant="h6">{organisations?.[organisationIndex]?.name}</Typography>
      ) : (
        <>
          <Button
            variant="outlined"
            color="inherit"
            disableElevation
            onClick={event => setAnchorEl(event.currentTarget)}
            endIcon={<ChevronDownIcon />}
          >
            {organisations?.[organisationIndex]?.name}
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
            {organisations?.map((organisation, index) => (
              <MenuItem
                key={organisation.id}
                selected={index === organisationIndex}
                onClick={() => {
                  setOrganisationIndex(index);
                  setAnchorEl(null);
                }}
              >
                {organisation.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
};
