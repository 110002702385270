import { baseApi, useAppDispatch, UserDto } from '@sgde/core';
import { useState } from 'react';
import { OrganisationDto } from '../models/dto/organisation/organisation.ts';

const organisationApi = baseApi.enhanceEndpoints({ addTagTypes: ['Organisations'] }).injectEndpoints({
  endpoints: build => ({
    getOrganisations: build.query<OrganisationDto[], void>({
      query: () => '/organisations',
      providesTags: ['Organisations'],
    }),
    createOrganisation: build.mutation<void, OrganisationDto>({
      query: organisation => ({
        url: `/organisations`,
        method: 'POST',
        body: organisation,
      }),
      invalidatesTags: ['Organisations'],
    }),
    updateOrganisation: build.mutation<void, OrganisationDto>({
      query: organisation => ({
        url: `/organisations`,
        method: 'PUT',
        body: organisation,
      }),
      invalidatesTags: ['Organisations'],
    }),
    deleteOrganisation: build.mutation<void, number[]>({
      query: ids => ({ url: `/organisations/${ids}`, method: 'DELETE' }),
      invalidatesTags: ['Organisations'],
    }),
    addOrganisationMember: build.mutation<void, { id: number; memberEmail: string }>({
      query: ({ id, memberEmail }) => ({ url: `/organisations/${id}/members/${memberEmail}`, method: 'POST' }),
      invalidatesTags: ['Organisations'],
    }),
    removeOrganisationMember: build.mutation<void, { id: number; memberEmail: string }>({
      query: ({ id, memberEmail }) => ({ url: `/organisations/${id}/members/${memberEmail}`, method: 'DELETE' }),
      invalidatesTags: ['Organisations'],
    }),
  }),
});

export const {
  useGetOrganisationsQuery: useOrganisations,
  useCreateOrganisationMutation: useCreateOrganisation,
  useUpdateOrganisationMutation: useUpdateOrganisation,
  useDeleteOrganisationMutation: useDeleteOrganisation,
  useAddOrganisationMemberMutation: useAddOrganisationMember,
  useRemoveOrganisationMemberMutation: useRemoveOrganisationMember,
} = organisationApi;

export const useCreateOrganisationLocally = () => {
  const dispatch = useAppDispatch();
  const [newOrganisation, setNewOrganisation] = useState<OrganisationDto | undefined>();
  return [
    () =>
      dispatch(
        organisationApi.util.updateQueryData('getOrganisations', undefined, organisations => {
          const localOrganisations = organisations?.find(org => !org.id);
          if (!localOrganisations) {
            organisations.unshift(DEFAULT_ORGANISATION);
            setNewOrganisation(DEFAULT_ORGANISATION);
          }
        })
      ),
    { data: newOrganisation },
  ] as const;
};

const DEFAULT_ORGANISATION = { id: 0, name: '', owner: {} as UserDto, users: [] } as OrganisationDto;
