import { alpha, makeStyles } from '@sgde/core';

type StyleParams = {
  heroImageUrl: string;
};

export const useStyles = makeStyles<StyleParams>()((theme, { heroImageUrl }) => ({
  root: {
    backgroundImage: `url(${heroImageUrl})`,
    backgroundColor: alpha(theme.palette.primary.dark, 0.35),
    color: theme.palette.primary.contrastText,
    backgroundBlendMode: 'overlay',
    backgroundPosition: 'center',
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(8),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
  },
  title: {
    flex: '1 0 50vw',

    [theme.breakpoints.down('md')]: {
      flex: 'none',
      '& h2': {
        textAlign: 'center',
      },
    },
  },
}));
