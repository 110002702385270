import { baseApi } from '@sgde/core';
import { ProfileDto } from '../models/dto/user/profile';

const userApi = baseApi.enhanceEndpoints({ addTagTypes: ['UserProfile'] }).injectEndpoints({
  endpoints: build => ({
    getCurrentUserProfile: build.query<ProfileDto, void>({
      query: () => '/users/current/profile',
      providesTags: ['UserProfile'],
    }),
    updateCurentUserProfile: build.mutation<void, Partial<ProfileDto>>({
      query: body => ({
        url: '/users/current/profile',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserProfile'],
    }),
  }),
});

export const {
  useGetCurrentUserProfileQuery: useCurrentUserProfile,
  useUpdateCurentUserProfileMutation: useUpdateCurrentUserProfile,
} = userApi;
