import { makeStyles } from '../theme.ts';

export const useStyles = makeStyles()(theme => ({
  paper: {
    backgroundColor: theme.palette.secondary.light,
    gap: theme.spacing(10),
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    color: theme.palette.primary.contrastText,
    marginTop: 'auto',
  },
  rootStack: {
    flexDirection: 'row',
    gap: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  logo: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  stack: {
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  linksContainer: {
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  link: {
    textDecoration: 'none',
  },
  divider: {
    borderColor: theme.palette.primary.dark,
  },
  contactDetails: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(14),
  },
}));
