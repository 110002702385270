import { useEffect, useRef } from 'react';

import { Box, Stack } from '@sgde/core';
import { ConversationDto } from '../../models/dto/chat/conversation.ts';
import { useConversationMessages } from '../../store/chatApi.ts';
import { Message } from './Message.tsx';
import { useStyles } from './Messages.styles.ts';

type Props = {
  conversation: ConversationDto;
};
export const Messages = ({ conversation }: Props) => {
  const { classes } = useStyles();
  const { data: messages } = useConversationMessages({ id: conversation.id }, { skip: !conversation.id });
  const messagesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (messagesRef?.current) {
      messagesRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [messages?.length]);

  return (
    <Box className={classes.messagesWrapper} ref={messagesRef}>
      <Stack className={classes.messages}>
        {!!(conversation.id && messages) &&
          messages.map(message => (
            <Message
              key={message.id}
              message={message.text}
              feedback={{ ...message.feedback, messageId: message.id, conversationId: conversation.id }}
              variant={message.type === 'User' ? 'question' : 'response'}
            />
          ))}
      </Stack>
    </Box>
  );
};
