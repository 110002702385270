import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(3),
      gridTemplateColumns: '1fr',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(6),
    gap: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },

    '&:last-child': { paddingBottom: theme.spacing(6) },
    '& .MuiTextField-root': { width: '100%' },
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));
