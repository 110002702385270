import { Configuration, LogLevel } from '@azure/msal-browser';
import { CONFIG } from '../utils';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: CONFIG.AZURE_B2C_CLIENT_ID,
    authority: `https://${CONFIG.AZURE_B2C_INSTANCE}/${CONFIG.AZURE_B2C_TENANT_ID || CONFIG.AZURE_B2C_DOMAIN}/B2C_1_signup_signin`,
    knownAuthorities: [CONFIG.AZURE_B2C_INSTANCE],
    redirectUri: '/chat',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: [`https://${CONFIG.AZURE_B2C_DOMAIN}/all-api/all.full`],
  extraQueryParameters: { ui_locales: 'ro' },
};
