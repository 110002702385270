import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    padding: '0 12px',
  },
  image: {
    display: 'block',
    maxHeight: '100px',
    maxWidth: '100%',
    margin: 'auto',
  },
}));
