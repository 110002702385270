import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import { ThemeMode } from '../models';
import { ThemeModeContext, ThemeModeContextProps, useTheme } from '../theme.ts';

export const ThemeProvider = ({ children }: { children: ReactElement }) => {
  const [mode, setMode] = useState<ThemeMode>((localStorage.getItem('themeMode') as ThemeMode) || ThemeMode.System);
  const { theme } = useTheme({ mode });
  const colorMode = useMemo(
    () =>
      ({
        themeMode: mode,
        setThemeMode: (mode: ThemeMode) => {
          localStorage.setItem('themeMode', mode);
          setMode(mode);
        },
      }) as ThemeModeContextProps,
    [mode]
  );

  return (
    theme && (
      <ThemeModeContext.Provider value={colorMode}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </MuiThemeProvider>
      </ThemeModeContext.Provider>
    )
  );
};

type ThemeWrapperProps = {
  mode: ThemeMode;
  children: ReactElement;
};

export const ThemeWrapper = ({ mode, children }: ThemeWrapperProps) => {
  const { theme } = useTheme({ mode });
  return theme && <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
