import {
  Box,
  NewsletterType,
  Paper,
  SendIcon,
  Stack,
  NewsletterSubscribeDto as SubscribeProps,
  TextField,
  ThemeMode,
  ThemeWrapper,
  Typography,
  isEmail,
  useAppInsights,
  useSnackbar,
  useSubscribe,
} from '@sgde/core';
import { useEffect, useState } from 'react';
import { useStyles } from './Subscribe.styles';

export const Subscribe = ({ mainText, secondaryText, inputPlaceholder }: SubscribeProps) => {
  const { classes } = useStyles();
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [subscribe, { isSuccess, error }] = useSubscribe();
  const appInsights = useAppInsights();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (!email || isEmail(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if (isSuccess) {
      setEmail('');
      openSnackbar({ message: 'V-ați abonat cu succes.', severity: 'success' });
    }

    if (error && 'status' in error) {
      openSnackbar({ message: error.data as string, severity: 'error' });
    }
  }, [error, isSuccess, openSnackbar]);

  const handleSubscribe = () => {
    appInsights.trackEvent({ name: 'Subscribe', properties: { email } });
    subscribe({ email, type: NewsletterType.ChatLaunch });
  };

  return (
    <Paper className={classes.root}>
      <Box>
        <Typography variant="h3" fontWeight="bold">
          {mainText}
        </Typography>
      </Box>
      <Stack gap={4}>
        <Typography variant="h5">{secondaryText}</Typography>
        <ThemeWrapper mode={ThemeMode.Dark}>
          <TextField
            label={inputPlaceholder}
            fullWidth
            required
            type="email"
            color="secondary"
            value={email}
            error={!isValidEmail}
            helperText={!isValidEmail ? 'Adresa de email este invalida.' : ''}
            onChange={({ target: { value: email } }) => setEmail(email)}
            onKeyDown={({ key }) => {
              if (key === 'Enter' && email && isValidEmail) {
                handleSubscribe();
              }
            }}
            InputProps={{
              endAdornment: (
                <SendIcon
                  color={email && isValidEmail ? 'secondary' : 'disabled'}
                  sx={{ cursor: email && isValidEmail ? 'pointer' : 'not-allowed' }}
                  onClick={handleSubscribe}
                />
              ),
            }}
          />
        </ThemeWrapper>
      </Stack>
    </Paper>
  );
};
