if (!window.config) {
  throw new Error('Missing config, configure in config.js file');
}

if (!window.config.baseApiDomain) {
  throw new Error('Missing baseApiDomain, configure in config.js file');
}

if (!window.config.siteApiDomain) {
  throw new Error('Missing siteApiDomain, configure in config.js file');
}

if (!window.config.appInsightsConnectionString) {
  throw new Error('Missing appInsightsConnectionString, configure in config.js file');
}

if (!window.config.azureB2cInstance) {
  throw new Error('Missing azureB2cInstance, configure in config.js file');
}

if (!window.config.azureB2cDomain) {
  throw new Error('Missing azureB2cDomain, configure in config.js file');
}

if (!window.config.azureB2cClientId) {
  throw new Error('Missing azureB2cClientId, configure in config.js file');
}

if (!window.config.azureB2cTenantId) {
  throw new Error('Missing azureB2cTenantId, configure in config.js file');
}

export const CONFIG = {
  BASE_API_URL: `${window.config.baseApiDomain}/api/v2`,
  SITE_API_URL: `${window.config.siteApiDomain}`,
  APP_INSIGHTS_CONNECTION_STRING: window.config.appInsightsConnectionString,
  AZURE_B2C_INSTANCE: window.config.azureB2cInstance,
  AZURE_B2C_DOMAIN: window.config.azureB2cDomain,
  AZURE_B2C_CLIENT_ID: window.config.azureB2cClientId,
  AZURE_B2C_TENANT_ID: window.config.azureB2cTenantId,
};
