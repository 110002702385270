import { gridClasses, makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    borderWidth: 0,

    [`& .${gridClasses.columnHeaderTitleContainer}`]: {
      justifyContent: 'space-between',
    },
    [`& .${gridClasses.columnSeparator}:not(.${gridClasses['columnSeparator--resizable']})`]: {
      display: 'none',
    },
  },
  disabled: {
    '&.Mui-disabled': { pointerEvents: 'auto' },
  },
  disabledLink: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
}));
