import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  message: {
    gap: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      gap: theme.spacing(1),
    },
  },
  avatar: {
    alignSelf: 'start',
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));
