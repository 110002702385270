import { Paper, PartnerListDto as PartnersProps } from '@sgde/core';
import { useStyles } from './Partners.styles.ts';

export const Partners = ({ items }: PartnersProps) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
      {items?.map(partner => (
        <img
          key={partner.id}
          src={partner.image.url}
          alt="microsoft for startups - founders hub"
          className={classes.image}
        />
      ))}
    </Paper>
  );
};
