import { makeStyles } from '@sgde/core';

type ActivityCardStylesProps = {
  imageSrc?: string;
  extend?: boolean;
};

export const useStyles = makeStyles<ActivityCardStylesProps>()((theme, { imageSrc, extend }) => ({
  root: {
    minHeight: theme.spacing(38),
    gridRow: extend ? 'span 2' : 'unset',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: imageSrc
      ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%), url(${imageSrc})`
      : 'unset',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      display: imageSrc ? 'unset' : 'none',
    },
  },
  content: {
    padding: imageSrc ? theme.spacing(4) : `${theme.spacing(9)} ${theme.spacing(4)}`,
    '&:last-child': { paddingBottom: imageSrc ? theme.spacing(4) : theme.spacing(9) },
  },
  featureBullet: {
    verticalAlign: 'text-top',
  },
  divider: {
    margin: '24px 0',
    borderBottomWidth: 'medium',
  },
}));
