import { Box, Button, HeroDto as HeroProps, Paper, Stack, Typography, useNavigate } from '@sgde/core';
import { useStyles } from './Hero.styles.ts';

export const Hero = ({ image, mainText, secondaryText, title, ctaList }: HeroProps) => {
  const { classes } = useStyles({ heroImageUrl: image?.url });
  const navigate = useNavigate();

  return (
    <Paper className={classes.root}>
      <Box className={classes.title}>
        <Typography variant="h2" fontWeight="bold">
          {mainText}
        </Typography>
        <Typography variant="h2" fontWeight="bold">
          {secondaryText}
        </Typography>
      </Box>
      <Stack gap={4}>
        <Typography variant="h4">{title}</Typography>
        <Stack gap={2} direction={{ md: 'column', lg: 'row' }}>
          {ctaList?.map((cta, key) => (
            <Button key={key} variant="contained" color="secondary" onClick={() => navigate(cta.url)} fullWidth>
              {cta.text}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};
