import { Box, Container, Header, Outlet, useBranding, useScrollTrigger } from '@sgde/core';
import { ReactElement, useEffect, useState } from 'react';
import { useStyles } from './Layout.styles.ts';
import { Logo } from './Logo.tsx';
import { ProfileMenu } from './ProfileMenu.tsx';

export const Layout = ({ children }: { children?: ReactElement }) => {
  const { classes } = useStyles();
  const { data: branding, isLoading } = useBranding();
  const [contentNode, setContentNode] = useState<Node | undefined>(undefined);
  const elevateHeader = useScrollTrigger({
    disableHysteresis: true,
    target: contentNode,
    threshold: 0,
  });

  useEffect(() => {
    changeFavicon(branding?.favicon || '/agro-chat.ico');
    document.title = branding?.title || 'SGDE';
  }, [branding?.title, branding?.favicon, isLoading]);

  return (
    <Container maxWidth="xl" disableGutters className={classes.root}>
      <Header
        logo={<Logo />}
        profileMenuItems={<ProfileMenu />}
        elevation={elevateHeader ? 4 : 0}
        className={classes.header}
      />
      <Box component="main" ref={node => setContentNode((node as Node) || undefined)} className={classes.content}>
        {children ?? <Outlet />}
      </Box>
    </Container>
  );
};

const changeFavicon = (faviconPath: string) => {
  let favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    document.head.appendChild(favicon);
  }

  favicon.href = faviconPath;
};
