import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  conversationIcon: {
    minWidth: theme.spacing(4),
  },
  conversationName: {
    span: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  conversation: {
    '.MuiListItemSecondaryAction-root': {
      visibility: 'hidden',
    },
    '&:hover, :focus-within, .Mui-selected+': {
      '.MuiListItemSecondaryAction-root': {
        visibility: 'visible',
      },
    },
  },
  conversationsHistoryButton: {
    marginLeft: 'auto',

    [theme.breakpoints.not('xs')]: {
      display: 'none',
    },
  },
}));
