import { useDispatch } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { adminApi } from './adminApi.ts';
import { baseApi } from './baseApi.ts';

export const coreStore = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseApi.middleware, adminApi.middleware),
});

setupListeners(coreStore.dispatch);

export type RootState = ReturnType<typeof coreStore.getState>;
export type AppDispatch = typeof coreStore.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
