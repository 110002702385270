import { Stack, useSitePage } from '@sgde/core';
import { ActivityAreas } from '../components/home/ActivityAreas.tsx';
import { Hero } from '../components/home/Hero.tsx';
import { Partners } from '../components/home/Partners.tsx';
import { Subscribe } from '../components/home/Subscribe.tsx';
import { Teasers } from '../components/home/Teasers.tsx';

export const Home = () => {
  const { data: homepage } = useSitePage({ pageName: 'homepage' });

  return (
    <Stack gap={{ xs: 3, md: 5 }}>
      {homepage?.hero && <Hero {...homepage.hero} />}
      {homepage?.partners && <Partners {...homepage.partners} />}
      {homepage?.teasers && <Teasers {...homepage.teasers} />}
      {homepage?.cardLists && <ActivityAreas cardLists={homepage.cardLists} />}
      {homepage?.newsletterSubscription && <Subscribe {...homepage.newsletterSubscription} />}
    </Stack>
  );
};
