import { Paper, Stack, TeaserListDto as TeasersProps, Typography } from '@sgde/core';
import { useStyles } from './Teasers.styles';

export const Teasers = ({ items }: TeasersProps) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
      {items?.map(teaser => (
        <Stack key={teaser.id} gap={1} alignItems="center">
          <img src={teaser.image.url} alt={teaser.image.alternativeText} />
          <Typography variant="h5" textAlign="center">
            {teaser.text}
          </Typography>
        </Stack>
      ))}
    </Paper>
  );
};
