import {
  AddIcon,
  GridPaginationModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
  IconButton,
} from '@sgde/core';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { useCreateResource } from '../../store/resourceApi.ts';
import { useStyles } from './ResourcesTableToolbar.styles.ts';

interface IProps {
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  setPaginationModel: Dispatch<SetStateAction<GridPaginationModel>>;
}

export const Toolbar = ({ setRowModesModel, setPaginationModel }: IProps) => {
  const { classes } = useStyles();
  const [createResource, { data: resource, isLoading }] = useCreateResource();

  const addResource = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    createResource(file);
    setPaginationModel(pagination => ({ ...pagination, page: 0 }));
  };

  useEffect(() => {
    if (resource) {
      setRowModesModel(model => ({
        ...model,
        [resource.id!]: { mode: GridRowModes.Edit, fieldToFocus: 'title' },
      }));
    }
  }, [resource, setRowModesModel]);

  return (
    <GridToolbarContainer className={classes.root}>
      <IconButton tabIndex={-1} component="label" role={undefined} disabled={isLoading}>
        <AddIcon />
        <input type="file" className={classes.hiddenInput} onChange={addResource} />
      </IconButton>
    </GridToolbarContainer>
  );
};
