import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { msalConfig } from './config.ts';

export const msalInstance = new PublicClientApplication(msalConfig);

export const useMsalInstance = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const setup = () => {
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: EventMessage) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = (event.payload as AuthenticationResult)?.account;
        msalInstance.setActiveAccount(account);
      }
    });
  };

  useEffect(() => {
    msalInstance.initialize().then(() => {
      setup();
      setIsInitialized(true);
    });
  }, []);

  return isInitialized ? msalInstance : undefined;
};
