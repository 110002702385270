type Route = {
  path: string;
  name: string;
};

type Routes = Record<string, Route>;

export const ROUTES: Routes = {
  HOME: { path: '/', name: 'Acasa' },
  CHAT: { path: '/chat', name: 'Chat' },
  PROFILE: { path: '/profile', name: 'Profil' },
  RESOURCES: { path: '/resources', name: 'Resurse' },
  USERS: { path: '/users', name: 'Utilizatori' },
  ORGANISATION: { path: '/organisation', name: 'Organizatie' },
  ORGANISATIONS: { path: '/organisations', name: 'Organizatii' },
} as const;
