import { Alert, AlertColor, Snackbar as MuiSnackbar, Slide, SlideProps } from '@mui/material';

export interface ISnackbarProps {
  open: boolean;
  message: string;
  severity?: AlertColor;
  onClose: () => void;
}
export const Snackbar = ({ open, message, severity, onClose }: ISnackbarProps) => (
  <MuiSnackbar open={open} autoHideDuration={5000} onClose={onClose} TransitionComponent={SlideTransition}>
    <Alert severity={severity} variant="standard" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </MuiSnackbar>
);

const SlideTransition = ({ children, ...props }: SlideProps) => (
  <Slide {...props} direction="right">
    {children}
  </Slide>
);
