import { AppBar, AppBarProps, Box, Stack, Toolbar } from '@mui/material';
import { Fragment, ReactElement } from 'react';
import { useHeader } from '../providers';
import { useStyles } from './Header.styles.ts';
import { ProfileMenu } from './ProfileMenu.tsx';

export type HeaderProps = AppBarProps & {
  logo: ReactElement;
  profileMenuItems?: ReactElement;
  hideLogin?: boolean;
};

export const Header = ({ logo, profileMenuItems, hideLogin, className, ...props }: HeaderProps) => {
  const { cx, classes } = useStyles();
  const { menuItems } = useHeader();

  return (
    <>
      <AppBar {...props} className={cx(classes.root, className)}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Box paddingRight={3}>{logo}</Box>
          <Stack direction="row" gap={1} flex={1}>
            {menuItems.map((item, index) => (
              <Fragment key={index}>{item}</Fragment>
            ))}
          </Stack>
          <Box ml="auto">
            <ProfileMenu hideLogin={hideLogin}>{profileMenuItems}</ProfileMenu>
          </Box>
        </Toolbar>
      </AppBar>
      {(!props.position || props.position === 'fixed' || props.position === 'absolute') && <Toolbar />}
    </>
  );
};
