import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { loginRequest } from '../auth/config.ts';
import { msalInstance } from '../auth/msal.tsx';
import { CONFIG } from '../utils';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: CONFIG.BASE_API_URL,
    mode: 'cors',
    credentials: 'include',
    prepareHeaders: async headers => {
      const account = msalInstance.getActiveAccount();
      if (!account) return headers;

      try {
        // If we have a token set in state, let's assume that we should be passing it.
        const auth = await msalInstance.acquireTokenSilent({ ...loginRequest });
        if (auth.accessToken) {
          headers.set('authorization', `Bearer ${auth.accessToken}`);
        }
      } catch {
        // We don't care if the acquireTokenSilent failed
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'api/base',
});
