import { makeStyles } from '@sgde/core';

type StyleProps = {
  counterText?: string;
  color: 'success' | 'warning' | 'error';
};

export const useStyles = makeStyles<StyleProps>()((theme, { counterText, color }) => ({
  root: {
    height: '100%',
    width: '100%',

    '> div': {
      height: '100%',
    },
  },
  inputWrapper: {
    width: '100%',
    marginTop: 'auto',
    paddingTop: theme.spacing(1 / 4),
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
    },
    '&::before': {
      content: `"${counterText}"`,
      color: theme.palette[color].main,
      position: 'absolute',
      top: theme.spacing(-1.4),
      overflow: 'hidden',
      maxWidth: '100%',
      margin: '0',
      display: 'block',
      padding: theme.spacing(0.5),
      right: theme.spacing(1.2),
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ffffff4d',
      lineHeight: '0.75rem',
      borderRadius: '3px',
      zIndex: 2,
      fontSize: '0.75rem',
    },
  },
  adornment: {
    height: theme.spacing(3),
  },
  info: {
    alignItems: 'center',
    gap: theme.spacing(1 / 2),
    marginTop: theme.spacing(1),
  },
}));
