import {
  ConversationIcon,
  GroupsIcon,
  Link,
  ListItemIcon,
  MenuItem,
  OrganisationIcon,
  ProfileIcon,
  ResourcesIcon,
  useCurrentUser,
  useLocation,
  UsersIcon,
  useUserRoles,
} from '@sgde/core';
import { useMemo } from 'react';
import { ROUTES } from '../router/routes.ts';
import { useOrganisations } from '../store/organisationApi.ts';

export const ProfileMenu = () => {
  const { pathname } = useLocation();
  const { data: user } = useCurrentUser();
  const { hasRole } = useUserRoles();
  const { data: organisations } = useOrganisations(undefined, { skip: !user });
  const hasOrganisations = useMemo(() => organisations && organisations.length > 0, [organisations]);

  return (
    <>
      <MenuItem component={Link} href={ROUTES.CHAT.path} selected={pathname === ROUTES.CHAT.path}>
        <ListItemIcon>
          <ConversationIcon fontSize="small" />
        </ListItemIcon>
        {ROUTES.CHAT.name}
      </MenuItem>
      {!!user && (
        <MenuItem component={Link} href={ROUTES.PROFILE.path} selected={pathname === ROUTES.PROFILE.path}>
          <ListItemIcon>
            <ProfileIcon fontSize="small" />
          </ListItemIcon>
          {ROUTES.PROFILE.name}
        </MenuItem>
      )}
      {hasRole('Admin') && (
        <MenuItem component={Link} href={ROUTES.USERS.path} selected={pathname === ROUTES.USERS.path}>
          <ListItemIcon>
            <UsersIcon fontSize="small" />
          </ListItemIcon>
          {ROUTES.USERS.name}
        </MenuItem>
      )}
      {(hasRole('Admin') || hasOrganisations) && (
        <MenuItem component={Link} href={ROUTES.ORGANISATION.path} selected={pathname === ROUTES.ORGANISATION.path}>
          <ListItemIcon>
            <GroupsIcon fontSize="small" />
          </ListItemIcon>
          {ROUTES.ORGANISATION.name}
        </MenuItem>
      )}
      {hasRole('Admin') && (
        <MenuItem component={Link} href={ROUTES.ORGANISATIONS.path} selected={pathname === ROUTES.ORGANISATIONS.path}>
          <ListItemIcon>
            <OrganisationIcon fontSize="small" />
          </ListItemIcon>
          {ROUTES.ORGANISATIONS.name}
        </MenuItem>
      )}
      {hasRole('Contributor') && (
        <MenuItem component={Link} href={ROUTES.RESOURCES.path} selected={pathname === ROUTES.RESOURCES.path}>
          <ListItemIcon>
            <ResourcesIcon fontSize="small" />
          </ListItemIcon>
          {ROUTES.RESOURCES.name}
        </MenuItem>
      )}
    </>
  );
};
