import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  header: {
    '& > .MuiToolbar-root': {
      margin: 'auto',
      width: '100%',
      maxWidth: theme.breakpoints.values.xl,
      padding: `0 ${theme.spacing(8)}`,
      [theme.breakpoints.down('md')]: {
        padding: `0 ${theme.spacing(3)}`,
      },
    },
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(8),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
  },
}));
